import { CasinoOutlined, Help, Info, Leaderboard, LocalFireDepartment, PercentOutlined, SportsScoreOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import './App.css';
import Card from './Card/Card';
import Popup from './Popup/Popup';
import { useCookies } from 'react-cookie';
import CookieConsent from "react-cookie-consent";

const birds = [
    ["Palm cockatoo", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Palm_Cockatoo_0A2A7769.jpg/450px-Palm_Cockatoo_0A2A7769.jpg"],
    ["Réunion stonechat", "//upload.wikimedia.org/wikipedia/commons/thumb/3/35/R%C3%A9union_stonechat_%28Saxicola_tectes%29_male.jpg/450px-R%C3%A9union_stonechat_%28Saxicola_tectes%29_male.jpg"],
    ["Mauritius fody", "//upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Mauritius_fody_%28Foudia_rubra%29_male_2.jpg/450px-Mauritius_fody_%28Foudia_rubra%29_male_2.jpg"],
    ["Mauritius kestrel", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Mauritius_kestrel_%28Falco_punctatus%29.jpg/200px-Mauritius_kestrel_%28Falco_punctatus%29.jpg"],
    ["Nelson's sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Nelson%27s_sparrow_in_marsh.jpg/450px-Nelson%27s_sparrow_in_marsh.jpg"],
    ["Red-lored whistler", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Red-lored_Whistler_0A2A8896.jpg/450px-Red-lored_Whistler_0A2A8896.jpg"],
    ["Dusky grasswren", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Dusky_Grasswren_0A2A9591.jpg/450px-Dusky_Grasswren_0A2A9591.jpg"],
    ["Greater white-fronted goose", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Greater_white-fronted_goose_in_flight-1045.jpg/388px-Greater_white-fronted_goose_in_flight-1045.jpg"],
    ["House sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9b/House_sparrow_male_in_Prospect_Park_%2853532%29.jpg/386px-House_sparrow_male_in_Prospect_Park_%2853532%29.jpg"],
    ["Blue-throated macaw", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Blue-throated_macaw_in_flight.jpg/480px-Blue-throated_macaw_in_flight.jpg"],
    ["Cinnamon teal", "//upload.wikimedia.org/wikipedia/commons/thumb/8/83/Sarcelle_cannelle_%28Spatula_cyanoptera%29.jpg/456px-Sarcelle_cannelle_%28Spatula_cyanoptera%29.jpg"],
    ["Cinnamon quail-thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/9/95/Cinnamon_Quail-thrush_0A2A9195.jpg/450px-Cinnamon_Quail-thrush_0A2A9195.jpg"],
    ["Yellow-bellied flyrobin", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Yellow-bellied_Flyrobin_0A2A7419.jpg/450px-Yellow-bellied_Flyrobin_0A2A7419.jpg"],
    ["Spinifex pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Spinifex_Pigeon_0A2A1585.jpg/450px-Spinifex_Pigeon_0A2A1585.jpg"],
    ["Smooth-billed ani", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Smooth-billed_ani_%28Crotophaga_ani%29_GC.JPG/300px-Smooth-billed_ani_%28Crotophaga_ani%29_GC.JPG"],
    ["Dalmatian pelican", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Dalmatian_pelican_%28Pelecanus_crispus%29_in_flight_Danube_delta_2.jpg/450px-Dalmatian_pelican_%28Pelecanus_crispus%29_in_flight_Danube_delta_2.jpg"],
    ["White stork", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bb/White_stork_%28Ciconia_ciconia%29_in_flight_with_transmitter.jpg/450px-White_stork_%28Ciconia_ciconia%29_in_flight_with_transmitter.jpg"],
    ["Australasian shoveler", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Australasian_Shoveler_-_Goulds_Lagoon_Wildlife_Sanctuary.jpg/450px-Australasian_Shoveler_-_Goulds_Lagoon_Wildlife_Sanctuary.jpg"],
    ["Red-tailed hawk", "//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Buteo_jamaicensis_in_flight_at_Llano_Seco-1520.jpg/640px-Buteo_jamaicensis_in_flight_at_Llano_Seco-1520.jpg"],
    ["Splendid fairywren", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Splendid_Fairywren_8352.jpg/450px-Splendid_Fairywren_8352.jpg"],
    ["Dusky seaside sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/d/de/Dusky_Seaside_Sparrow.jpg/197px-Dusky_Seaside_Sparrow.jpg"],
    ["Black bittern", "//upload.wikimedia.org/wikipedia/commons/thumb/1/14/Black_Bittern-_Warriewood_Wetlands.jpg/300px-Black_Bittern-_Warriewood_Wetlands.jpg"],
    ["Wild turkey", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Male_wild_turkey_%28Meleagris_gallopavo%29_strutting.jpg/300px-Male_wild_turkey_%28Meleagris_gallopavo%29_strutting.jpg"],
    ["Chestnut-headed bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Chestnut-headed_bee-eater_%28Merops_leschenaulti%29_Yala.jpg/200px-Chestnut-headed_bee-eater_%28Merops_leschenaulti%29_Yala.jpg"],
    ["Northern cardinal", "//upload.wikimedia.org/wikipedia/commons/thumb/7/77/Northern_cardinal_female_in_CP_%2802035%29.jpg/301px-Northern_cardinal_female_in_CP_%2802035%29.jpg"],
    ["Northern mockingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Mockingbird_in_Bay_Ridge_%2885082%29.jpg/421px-Mockingbird_in_Bay_Ridge_%2885082%29.jpg"],
    ["Malabar pied hornbill", "//upload.wikimedia.org/wikipedia/commons/thumb/b/be/Malabar_pied_hornbill_%28Anthracoceros_coronatus%29_male.jpg/300px-Malabar_pied_hornbill_%28Anthracoceros_coronatus%29_male.jpg"],
    ["Gibson's albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Gibson%27s_Albatross_0A2A4153.jpg/450px-Gibson%27s_Albatross_0A2A4153.jpg"],
    ["White-faced storm petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4a/White-faced_Storm-petrel_0A2A9606.jpg/450px-White-faced_Storm-petrel_0A2A9606.jpg"],
    ["Marabou stork", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Marabou_stork_%28Leptoptilos_crumenifer%29_in_flight_2.jpg/450px-Marabou_stork_%28Leptoptilos_crumenifer%29_in_flight_2.jpg"],
    ["Bronze-winged jacana", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Bronze-winged_jacana_%28Metopidius_indicus%29.jpg/450px-Bronze-winged_jacana_%28Metopidius_indicus%29.jpg"],
    ["Village weaver", "//upload.wikimedia.org/wikipedia/commons/thumb/4/45/Village_weaver_%28Ploceus_cucullatus_cucullatus%29_male_with_leaf.jpg/300px-Village_weaver_%28Ploceus_cucullatus_cucullatus%29_male_with_leaf.jpg"],
    ["Grey-crowned babblers", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Grey-crowned_Babblers_1605.jpg/450px-Grey-crowned_Babblers_1605.jpg"],
    ["Crested honey buzzard", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Oriental_honey_buzzard_Mudumalai_Mar21_DSC01405.jpg/225px-Oriental_honey_buzzard_Mudumalai_Mar21_DSC01405.jpg"],
    ["Sedge warbler", "//upload.wikimedia.org/wikipedia/commons/thumb/6/69/Sedge_warbler_%28Acrocephalus_schoenobaenus%29_3.jpg/300px-Sedge_warbler_%28Acrocephalus_schoenobaenus%29_3.jpg"],
    ["Copper sunbird", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Copper_sunbird_%28Cinnyris_cupreus_cupreus%29_female_on_Persian_silk_tree_%28Albizia_julibrissin%29.jpg/300px-Copper_sunbird_%28Cinnyris_cupreus_cupreus%29_female_on_Persian_silk_tree_%28Albizia_julibrissin%29.jpg"],
    ["Crested caracara", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Schopfkarakara.jpg/450px-Schopfkarakara.jpg"],
    ["Orange chat", "//upload.wikimedia.org/wikipedia/commons/thumb/d/de/Orange_Chat_9034.jpg/450px-Orange_Chat_9034.jpg"],
    ["Green kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Green_kingfisher_%28Chloroceryle_americana%29_male_3.jpg/450px-Green_kingfisher_%28Chloroceryle_americana%29_male_3.jpg"],
    ["Greater crested tern", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Crested_Tern_-_Mortimer_Bay.jpg/300px-Crested_Tern_-_Mortimer_Bay.jpg"],
    ["Martial eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/6/61/Martial_eagle_%28Polemaetus_bellicosus%29.jpg/200px-Martial_eagle_%28Polemaetus_bellicosus%29.jpg"],
    ["Common moorhen", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Common_moorhen_%28Gallinula_chloropus%29_France.jpg/426px-Common_moorhen_%28Gallinula_chloropus%29_France.jpg"],
    ["Plains-wanderer", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Plains-wanderer_female_8173.jpg/450px-Plains-wanderer_female_8173.jpg"],
    ["Red-eyed dove", "//upload.wikimedia.org/wikipedia/commons/thumb/7/77/Red-eyed_dove_%28Streptopelia_semitorquata%29.jpg/200px-Red-eyed_dove_%28Streptopelia_semitorquata%29.jpg"],
    ["Sword-billed hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Sword-billed_hummingbird_%28male%29_at_Guango_Lodge%2C_Ecuador_%2821310837273%29.jpg/375px-Sword-billed_hummingbird_%28male%29_at_Guango_Lodge%2C_Ecuador_%2821310837273%29.jpg"],
    ["Acorn woodpecker", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Acorn_woodpecker_holding_a_nut_in_its_beak-0225.jpg/300px-Acorn_woodpecker_holding_a_nut_in_its_beak-0225.jpg"],
    ["Madagascar stonechat", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Madagascar_stonechat_%28Saxicola_sibilla%29_male.jpg/300px-Madagascar_stonechat_%28Saxicola_sibilla%29_male.jpg"],
    ["American white pelican", "//upload.wikimedia.org/wikipedia/commons/thumb/8/86/Pelecanus_erythrorhynchos_at_Las_Gallinas_Wildlife_Ponds.jpg/200px-Pelecanus_erythrorhynchos_at_Las_Gallinas_Wildlife_Ponds.jpg"],
    ["Bell miner", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Bell_Miner_1_-_Nepean_Weir.jpg/375px-Bell_Miner_1_-_Nepean_Weir.jpg"],
    ["Long-crested eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/3/38/Long-crested_eagle_%28Lophaetus_occipitalis%29_3.jpg/300px-Long-crested_eagle_%28Lophaetus_occipitalis%29_3.jpg"],
    ["Red-browed finch", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Red-browed_Finch_-_Penrith.jpg/300px-Red-browed_Finch_-_Penrith.jpg"],
    ["European bee-eaters", "//upload.wikimedia.org/wikipedia/commons/thumb/6/63/European_bee-eaters_%28Merops_apiaster%29_with_dragonflies.jpg/450px-European_bee-eaters_%28Merops_apiaster%29_with_dragonflies.jpg"],
    ["White-cheeked honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/a/aa/White-cheeked_Honeyeater_-_Maddens_Plains.jpg/300px-White-cheeked_Honeyeater_-_Maddens_Plains.jpg"],
    ["Atlantic puffin", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Puffin_%28Fratercula_arctica%29.jpg/300px-Puffin_%28Fratercula_arctica%29.jpg"],
    ["Spotted pardalote", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Pardalotus_punctatus_-_Glen_Davis.jpg/450px-Pardalotus_punctatus_-_Glen_Davis.jpg"],
    ["Northern masked weaver", "//upload.wikimedia.org/wikipedia/commons/thumb/6/60/Northern_masked_weaver_%28Ploceus_taeniopterus%29_female.jpg/200px-Northern_masked_weaver_%28Ploceus_taeniopterus%29_female.jpg"],
    ["Spotted dove", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Spotted_dove_%28Spilopelia_chinensis_suratensis%29.jpg/243px-Spotted_dove_%28Spilopelia_chinensis_suratensis%29.jpg"],
    ["Australian brushturkey", "//upload.wikimedia.org/wikipedia/commons/thumb/6/67/Australian_Brushturkey_2_-_Newington.jpg/300px-Australian_Brushturkey_2_-_Newington.jpg"],
    ["Scarlet-chested sunbird", "//upload.wikimedia.org/wikipedia/commons/thumb/8/83/Scarlet-chested_sunbird_%28Chalcomitra_senegalensis_lamperti%29_female_2.jpg/211px-Scarlet-chested_sunbird_%28Chalcomitra_senegalensis_lamperti%29_female_2.jpg"],
    ["Spur-winged lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/7/75/Spur-winged_lapwing_%28Vanellus_spinosus%29_in_flight.jpg/300px-Spur-winged_lapwing_%28Vanellus_spinosus%29_in_flight.jpg"],
    ["River tern", "//upload.wikimedia.org/wikipedia/commons/thumb/7/79/River_tern_%28Sterna_aurantia%29.jpg/450px-River_tern_%28Sterna_aurantia%29.jpg"],
    ["Violet-backed starling", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Violet-backed_starling_%28Cinnyricinclus_leucogaster_verreauxi%29_female.jpg/450px-Violet-backed_starling_%28Cinnyricinclus_leucogaster_verreauxi%29_female.jpg"],
    ["European stonechat", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Stonechat_%28Saxicola_rubicola%29_male%2C_Beaulieu%2C_Hampshire.jpg/276px-Stonechat_%28Saxicola_rubicola%29_male%2C_Beaulieu%2C_Hampshire.jpg"],
    ["Flame robin", "//upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flame_Robin_male_1_-_Jenolan_Caves.jpg/450px-Flame_Robin_male_1_-_Jenolan_Caves.jpg"],
    ["Brown-headed cowbird", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Brown_headed_cowbird_female_in_JBWR_%2825487%29.jpg/392px-Brown_headed_cowbird_female_in_JBWR_%2825487%29.jpg"],
    ["Pied mynas", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Asian_pied_starlings_%28Gracupica_contra%29.jpg/450px-Asian_pied_starlings_%28Gracupica_contra%29.jpg"],
    ["Buff-banded rail", "//upload.wikimedia.org/wikipedia/commons/thumb/4/45/Buff-banded_Rail_1_-_Newington.jpg/534px-Buff-banded_Rail_1_-_Newington.jpg"],
    ["Rusty-margined flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Rusty-margined_flycatcher_%28Myiozetetes_cayanensis_hellmayri%29.jpg/200px-Rusty-margined_flycatcher_%28Myiozetetes_cayanensis_hellmayri%29.jpg"],
    ["Red-and-green macaw", "//upload.wikimedia.org/wikipedia/commons/thumb/4/42/Red-and-green_macaw_%28Ara_chloropterus%29_juvenile.JPG/450px-Red-and-green_macaw_%28Ara_chloropterus%29_juvenile.JPG"],
    ["Jabiru", "//upload.wikimedia.org/wikipedia/commons/thumb/3/33/Jabiru_%28Jabiru_mycteria%29_2.JPG/300px-Jabiru_%28Jabiru_mycteria%29_2.JPG"],
    ["Black-faced woodswallow", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Black-faced_Woodswallow_1_-_Sturt_National_Park.jpg/450px-Black-faced_Woodswallow_1_-_Sturt_National_Park.jpg"],
    ["Pied kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Pied_kingfisher_%28Ceryle_rudis_leucomelanurus%29_male.jpg/300px-Pied_kingfisher_%28Ceryle_rudis_leucomelanurus%29_male.jpg"],
    ["Rose-ringed parakeet", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Rose-ringed_parakeet_%28Psittacula_krameri_borealis%29_male_Jaipur_2.jpg/200px-Rose-ringed_parakeet_%28Psittacula_krameri_borealis%29_male_Jaipur_2.jpg"],
    ["African fish eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/8/87/African_fish_eagle_%28Haliaeetus_vocifer%29_Ethiopia.jpg/300px-African_fish_eagle_%28Haliaeetus_vocifer%29_Ethiopia.jpg"],
    ["Black-fronted dotterel", "//upload.wikimedia.org/wikipedia/commons/thumb/3/34/Black-fronted_Dotterel_2_-_Bow_Bowing.jpg/450px-Black-fronted_Dotterel_2_-_Bow_Bowing.jpg"],
    ["Brown pelican", "//upload.wikimedia.org/wikipedia/commons/thumb/0/06/Brown_pelican_in_flight_%28Bodega_Bay%29.jpg/420px-Brown_pelican_in_flight_%28Bodega_Bay%29.jpg"],
    ["Major Mitchell's cockatoo", "//upload.wikimedia.org/wikipedia/commons/thumb/7/74/Major_Mitchell%27s_Cockatoo_1_-_Mt_Grenfell.jpg/450px-Major_Mitchell%27s_Cockatoo_1_-_Mt_Grenfell.jpg"],
    ["Field sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Field_sparrow_in_CP_%2841484%29_%28cropped%29.jpg/367px-Field_sparrow_in_CP_%2841484%29_%28cropped%29.jpg"],
    ["Yellow-bellied sapsucker", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Yellow-bellied_sapsucker_in_CP_%2840484%29.jpg/367px-Yellow-bellied_sapsucker_in_CP_%2840484%29.jpg"],
    ["Northern pintail", "//upload.wikimedia.org/wikipedia/commons/thumb/d/df/Male_northern_pintail_at_Llano_Seco.jpg/480px-Male_northern_pintail_at_Llano_Seco.jpg"],
    ["Yellow-throated miner", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Yellow-throated_Miner_-_Sturt_National_Park.jpg/300px-Yellow-throated_Miner_-_Sturt_National_Park.jpg"],
    ["Mistletoebird", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Mistletoebird_-_Round_Hill_Nature_Reserve.jpg/450px-Mistletoebird_-_Round_Hill_Nature_Reserve.jpg"],
    ["Lavender waxbill", "//upload.wikimedia.org/wikipedia/commons/thumb/4/45/Lavender_waxbill_%28Estrilda_caerulescens%29.jpg/300px-Lavender_waxbill_%28Estrilda_caerulescens%29.jpg"],
    ["Common buzzard", "//upload.wikimedia.org/wikipedia/commons/thumb/a/af/Steppe_buzzard_%28Buteo_buteo_vulpinus%29.jpg/200px-Steppe_buzzard_%28Buteo_buteo_vulpinus%29.jpg"],
    ["Scarlet robin", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Scarlet_Robin_female_-_Blackheath.jpg/300px-Scarlet_Robin_female_-_Blackheath.jpg"],
    ["Eurasian nuthatch", "//upload.wikimedia.org/wikipedia/commons/thumb/9/91/Kleiber_Sitta_europaea-0447.jpg/450px-Kleiber_Sitta_europaea-0447.jpg"],
    ["Emu", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Emu_1_-_Tidbinbilla.jpg/375px-Emu_1_-_Tidbinbilla.jpg"],
    ["Eurasian coot", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Eurasian_coot_%28Fulica_atra%29_juvenile.jpg/450px-Eurasian_coot_%28Fulica_atra%29_juvenile.jpg"],
    ["Lilac-breasted roller", "//upload.wikimedia.org/wikipedia/commons/thumb/0/06/Lilac-breasted_roller_%28Coracias_caudatus_caudatus%29_Botswana.jpg/450px-Lilac-breasted_roller_%28Coracias_caudatus_caudatus%29_Botswana.jpg"],
    ["Talamanca hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/7/73/Talamanca_hummingbird_%28Eugenes_spectabilis%29_male.jpg/300px-Talamanca_hummingbird_%28Eugenes_spectabilis%29_male.jpg"],
    ["Marabou stork", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Marabou_stork_%28Leptoptilos_crumenifer%29_head.jpg/450px-Marabou_stork_%28Leptoptilos_crumenifer%29_head.jpg"],
    ["Turkey vulture", "//upload.wikimedia.org/wikipedia/commons/thumb/4/40/Turkey_vulture_%28Cathartes_aura%29_in_flight.JPG/450px-Turkey_vulture_%28Cathartes_aura%29_in_flight.JPG"],
    ["Green-crowned brilliant", "//upload.wikimedia.org/wikipedia/commons/thumb/5/50/Green-crowned_brilliant_%28Heliodoxa_jacula_henryi%29_female.jpg/300px-Green-crowned_brilliant_%28Heliodoxa_jacula_henryi%29_female.jpg"],
    ["Common kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/5/58/Common_kingfisher_%28Alcedo_atthis_ispida%29_female.jpg/300px-Common_kingfisher_%28Alcedo_atthis_ispida%29_female.jpg"],
    ["Eastern bristlebird", "//upload.wikimedia.org/wikipedia/commons/thumb/9/95/Eastern_Bristlebird_-_Penrith.jpg/450px-Eastern_Bristlebird_-_Penrith.jpg"],
    ["Rose robin", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Rose_Robin_1_-_Woodford.jpg/450px-Rose_Robin_1_-_Woodford.jpg"],
    ["Blue-billed duck", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Oxyura_australis_male_2_-_Penrith.jpg/450px-Oxyura_australis_male_2_-_Penrith.jpg"],
    ["Black skimmer feeding", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Black_skimmer_%28Rynchops_niger%29_in_flight.jpg/545px-Black_skimmer_%28Rynchops_niger%29_in_flight.jpg"],
    ["Little bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Little_bee-eater_%28Merops_pusillus_argutus%29_Namibia.jpg/244px-Little_bee-eater_%28Merops_pusillus_argutus%29_Namibia.jpg"],
    ["Sociable weaver", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Sociable_weaver_%28Philetairus_socius%29.jpg/300px-Sociable_weaver_%28Philetairus_socius%29.jpg"],
    ["African sacred ibis", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Sacred_ibis_%28Threskiornis_aethiopicus%29.jpg/450px-Sacred_ibis_%28Threskiornis_aethiopicus%29.jpg"],
    ["Rosy-faced lovebird", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Rosy-faced_lovebird_%28Agapornis_roseicollis_roseicollis%29_2.jpg/300px-Rosy-faced_lovebird_%28Agapornis_roseicollis_roseicollis%29_2.jpg"],
    ["Purple roller", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Purple_roller_%28Coracias_naevius_mosambicus%29.jpg/200px-Purple_roller_%28Coracias_naevius_mosambicus%29.jpg"],
    ["Village weaver building a nest", "//upload.wikimedia.org/wikipedia/commons/thumb/7/71/Black-headed_weaver_%28Ploceus_cucullatus_bohndorffi%29_male_nest_building.jpg/450px-Black-headed_weaver_%28Ploceus_cucullatus_bohndorffi%29_male_nest_building.jpg"],
    ["Brown-headed honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/0/02/Brown-headed_Honeyeater_-_Patchewollock.jpg/450px-Brown-headed_Honeyeater_-_Patchewollock.jpg"],
    ["Buff-breasted paradise kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/9/98/Buff-breasted_Paradise-Kingfisher_-_Julatten.jpg/240px-Buff-breasted_Paradise-Kingfisher_-_Julatten.jpg"],
    ["Bee hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Bee_hummingbird_%28Mellisuga_helenae%29_immature_male.jpg/300px-Bee_hummingbird_%28Mellisuga_helenae%29_immature_male.jpg"],
    ["Yellow-billed cardinal", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Yellow-billed_cardinal_%28Paroaria_capitata%29_juvenile.JPG/450px-Yellow-billed_cardinal_%28Paroaria_capitata%29_juvenile.JPG"],
    ["Violet sabrewing", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Violet_sabrewing_%28Campylopterus_hemileucurus_mellitus%29_male_in_flight.jpg/300px-Violet_sabrewing_%28Campylopterus_hemileucurus_mellitus%29_male_in_flight.jpg"],
    ["Double-crested cormorant", "//upload.wikimedia.org/wikipedia/commons/thumb/a/af/Double-crested_cormorant_at_Sutro_Baths-6460.jpg/420px-Double-crested_cormorant_at_Sutro_Baths-6460.jpg"],
    ["White-fronted bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5c/White-fronted_bee-eater_%28Merops_bullockoides%29_Namibia.jpg/300px-White-fronted_bee-eater_%28Merops_bullockoides%29_Namibia.jpg"],
    ["African grey hornbill", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d3/African_Grey_Hornbill_%28Lophoceros_nasutus_epirhinus%29_female.jpg/396px-African_Grey_Hornbill_%28Lophoceros_nasutus_epirhinus%29_female.jpg"],
    ["Spiny-cheeked honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Spiny-cheeked_Honeyeater_3434_-_Patchewollock_Conservation_Reserve.jpg/450px-Spiny-cheeked_Honeyeater_3434_-_Patchewollock_Conservation_Reserve.jpg"],
    ["Northern gannet", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Northern_Gannet_with_nest_material.jpg/451px-Northern_Gannet_with_nest_material.jpg"],
    ["Campo flicker", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Campo_flicker_%28Colaptes_campestris%29_female.JPG/200px-Campo_flicker_%28Colaptes_campestris%29_female.JPG"],
    ["Ortolan bunting", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Ortolan_bunting_in_Sierra_de_Guara%2C_Aragon%2C_Spain.jpg/450px-Ortolan_bunting_in_Sierra_de_Guara%2C_Aragon%2C_Spain.jpg"],
    ["Red knot", "//upload.wikimedia.org/wikipedia/commons/thumb/1/13/Red_Knot_1_-_Boat_Harbour.jpg/449px-Red_Knot_1_-_Boat_Harbour.jpg"],
    ["Willie wagtail", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Rhipidura_leucophrys_-_Glen_Davis.jpg/450px-Rhipidura_leucophrys_-_Glen_Davis.jpg"],
    ["Fuscous honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Ptilotula_fusca_-_Glen_Alice.jpg/300px-Ptilotula_fusca_-_Glen_Alice.jpg"],
    ["Diamond firetail", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Stagonopleura_guttata_1_-_Glen_Alice.jpg/450px-Stagonopleura_guttata_1_-_Glen_Alice.jpg"],
    ["African emerald cuckoo", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9c/African_emerald_cuckoo_%28Chrysococcyx_cupreus%29_male.jpg/300px-African_emerald_cuckoo_%28Chrysococcyx_cupreus%29_male.jpg"],
    ["Sapphire-throated hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/6/67/Sapphire-throated_hummingbird_%28Lepidopyga_coeruleogularis_coeruleogularis%29_male.jpg/366px-Sapphire-throated_hummingbird_%28Lepidopyga_coeruleogularis_coeruleogularis%29_male.jpg"],
    ["Double-barred finch", "//upload.wikimedia.org/wikipedia/commons/thumb/2/26/Taeniopygia_bichenovii_2_-_Glen_Davis.jpg/450px-Taeniopygia_bichenovii_2_-_Glen_Davis.jpg"],
    ["White-naped honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Melithreptus_lunatus.jpg/450px-Melithreptus_lunatus.jpg"],
    ["White-plumed honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Ptilotula_penicillata_-_Glen_Davis.jpg/300px-Ptilotula_penicillata_-_Glen_Davis.jpg"],
    ["Noisy friarbird", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Philemon_corniculatus_-_Glen_Davis.jpg/200px-Philemon_corniculatus_-_Glen_Davis.jpg"],
    ["Hooded robin", "//upload.wikimedia.org/wikipedia/commons/thumb/4/46/Melanodryas_cucullata_2_-_Glen_Davis.jpg/450px-Melanodryas_cucullata_2_-_Glen_Davis.jpg"],
    ["Northern lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Northern-Lapwing-Vanellus-vanellus.jpg/450px-Northern-Lapwing-Vanellus-vanellus.jpg"],
    ["White-headed petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ef/File-Pterodroma_lessonii_in_flight_1_-_SE_Tasmania_2019.jpg/450px-File-Pterodroma_lessonii_in_flight_1_-_SE_Tasmania_2019.jpg"],
    ["Red wattlebird", "//upload.wikimedia.org/wikipedia/commons/thumb/3/37/Red_wattlebird.jpg/300px-Red_wattlebird.jpg"],
    ["Brown quail", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Coturnix_ypsilophora_-_Sydney_Olympic_Park.jpg/375px-Coturnix_ypsilophora_-_Sydney_Olympic_Park.jpg"],
    ["Bar-shouldered dove", "//upload.wikimedia.org/wikipedia/commons/thumb/7/72/Geopelia_humeralis_-_Brunkerville.jpg/450px-Geopelia_humeralis_-_Brunkerville.jpg"],
    ["White-headed pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/8/84/Columba_leucomela_-_Brunkerville.jpg/450px-Columba_leucomela_-_Brunkerville.jpg"],
    ["Black-faced monarch", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Monarcha_melanopsis_1_-_Brunkerville.jpg/240px-Monarcha_melanopsis_1_-_Brunkerville.jpg"],
    ["Wonga pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/9/93/Leucosarcia_melanoleuca_-_Brunkerville.jpg/460px-Leucosarcia_melanoleuca_-_Brunkerville.jpg"],
    ["Australian owlet-nightjar", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Aegotheles_chrisoptus_-_Catlereigh_Nature_Reserve.jpg/300px-Aegotheles_chrisoptus_-_Catlereigh_Nature_Reserve.jpg"],
    ["Painted buttonquail", "//upload.wikimedia.org/wikipedia/commons/thumb/2/23/Turnix_varius_-_Castlereigh_nature_reserve.jpg/450px-Turnix_varius_-_Castlereigh_nature_reserve.jpg"],
    ["Eastern spinebill", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Acanthorhynchus_tenuirostris_-_Mogo_Campground.jpg/450px-Acanthorhynchus_tenuirostris_-_Mogo_Campground.jpg"],
    ["Eastern yellow robin", "//upload.wikimedia.org/wikipedia/commons/thumb/4/45/Eopsaltria_australis_-_Mogo_Campground.jpg/450px-Eopsaltria_australis_-_Mogo_Campground.jpg"],
    ["Snowy-bellied hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/3/37/Snowy-bellied_hummingbird_%28Amazilia_edward_niveoventer%29_1.jpg/300px-Snowy-bellied_hummingbird_%28Amazilia_edward_niveoventer%29_1.jpg"],
    ["Rufous-collared sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/5/50/Rufous-collared_sparrow_%28Zonotrichia_capensis_costaricensis%29_2.jpg/450px-Rufous-collared_sparrow_%28Zonotrichia_capensis_costaricensis%29_2.jpg"],
    ["Swallow-tailed bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/9/97/Swallow-tailed_bee-eater_%28Merops_hirundineus_chrysolaimus%29.jpg/300px-Swallow-tailed_bee-eater_%28Merops_hirundineus_chrysolaimus%29.jpg"],
    ["Palestine sunbird", "//upload.wikimedia.org/wikipedia/commons/thumb/9/91/Palestine_sunbird_%28Cinnyris_osea_osea%29_male.jpg/450px-Palestine_sunbird_%28Cinnyris_osea_osea%29_male.jpg"],
    ["Variable oystercatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/7/76/Haematopus_unicolor_-_Point_Chevalier.jpg/450px-Haematopus_unicolor_-_Point_Chevalier.jpg"],
    ["Southern rough-winged swallow", "//upload.wikimedia.org/wikipedia/commons/thumb/8/83/Southern_rough-winged_swallow_%28Stelgidopteryx_ruficollis_ruficollis%29.JPG/300px-Southern_rough-winged_swallow_%28Stelgidopteryx_ruficollis_ruficollis%29.JPG"],
    ["Song thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Turdus_philomelos_-_Western_Springs_Lakeside_Park.jpg/450px-Turdus_philomelos_-_Western_Springs_Lakeside_Park.jpg"],
    ["Red-billed gull", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Red-billed_gull%2C_Red_Zone%2C_Christchurch%2C_New_Zealand.jpg/366px-Red-billed_gull%2C_Red_Zone%2C_Christchurch%2C_New_Zealand.jpg"],
    ["South Island oystercatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/9/95/Haematopus_finschi_-_Point_Chevalier.jpg/450px-Haematopus_finschi_-_Point_Chevalier.jpg"],
    ["New Zealand plover", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Charadrius_obscurus_aquilonius_-_Point_Chevalier.jpg/450px-Charadrius_obscurus_aquilonius_-_Point_Chevalier.jpg"],
    ["Warbling white-eye", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Japanese_white-eye_at_Tenn%C5%8Dji_Park_in_Osaka%2C_January_2016_III.jpg/418px-Japanese_white-eye_at_Tenn%C5%8Dji_Park_in_Osaka%2C_January_2016_III.jpg"],
    ["Red-backed shrike", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Red-backed_shrike.jpg/481px-Red-backed_shrike.jpg"],
    ["Cape starling", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Cape_glossy_starling_%28Lamprotornis_nitens%29.jpg/377px-Cape_glossy_starling_%28Lamprotornis_nitens%29.jpg"],
    ["Ruddy turnstone", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Arenaria_interpres_2_-_Boat_Harbour_%28cropped%29.jpg/445px-Arenaria_interpres_2_-_Boat_Harbour_%28cropped%29.jpg"],
    ["Northern royal albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/8/84/Diomedea_sanfordi_-_SE_Tasmania_2019.jpg/450px-Diomedea_sanfordi_-_SE_Tasmania_2019.jpg"],
    ["Rockwarbler", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Origma_solitaria_2_-_Wattamolla.jpg/450px-Origma_solitaria_2_-_Wattamolla.jpg"],
    ["Soft-plumaged petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/9/90/Pterodroma_mollis_light_morph_-_SE_Tasmania_2019.jpg/450px-Pterodroma_mollis_light_morph_-_SE_Tasmania_2019.jpg"],
    ["Campbell albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/0/07/Thalassarche_impavida_2_-_SE_Tasmania.jpg/450px-Thalassarche_impavida_2_-_SE_Tasmania.jpg"],
    ["Red-billed streamertail", "//upload.wikimedia.org/wikipedia/commons/thumb/6/64/Red-billed_streamertail_%28Trochilus_polytmus%29_female_in_flight_2.JPG/450px-Red-billed_streamertail_%28Trochilus_polytmus%29_female_in_flight_2.JPG"],
    ["Eastern chanting goshawk", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Eastern_chanting_goshawk_%28Melierax_poliopterus%29.jpg/450px-Eastern_chanting_goshawk_%28Melierax_poliopterus%29.jpg"],
    ["Saddle-billed stork", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Jabir%C3%BA_africano_%28Ephippiorhynchus_senegalensis%29%2C_delta_del_Okavango%2C_Botsuana%2C_2018-07-31%2C_DD_11.jpg/534px-Jabir%C3%BA_africano_%28Ephippiorhynchus_senegalensis%29%2C_delta_del_Okavango%2C_Botsuana%2C_2018-07-31%2C_DD_11.jpg"],
    ["Red-rumped parrot", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Psephotus_haematonotus_male_-_Cornwallis_Rd.jpg/450px-Psephotus_haematonotus_male_-_Cornwallis_Rd.jpg"],
    ["Common blackbird", "//upload.wikimedia.org/wikipedia/commons/thumb/8/87/Common_Blackbird_%28Turdus_merula_mauritanicus%29_female.jpg/300px-Common_Blackbird_%28Turdus_merula_mauritanicus%29_female.jpg"],
    ["Violet-backed starling", "//upload.wikimedia.org/wikipedia/commons/thumb/0/05/Violet-backed_starling_%28Cinnyricinclus_leucogaster_verreauxi%29_male.jpg/450px-Violet-backed_starling_%28Cinnyricinclus_leucogaster_verreauxi%29_male.jpg"],
    ["Crested shriketit", "//upload.wikimedia.org/wikipedia/commons/thumb/a/af/Falcunculus_frontatus_-_Dharug_National_Park.jpg/450px-Falcunculus_frontatus_-_Dharug_National_Park.jpg"],
    ["Orange-headed thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/1/13/Zoothera_citrina_-_Khao_Yai.jpg/450px-Zoothera_citrina_-_Khao_Yai.jpg"],
    ["Bank myna", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Bank_myna_%28Acridotheres_ginginianus%29.jpg/300px-Bank_myna_%28Acridotheres_ginginianus%29.jpg"],
    ["Pacific reef heron", "//upload.wikimedia.org/wikipedia/commons/thumb/d/da/Egretta_sacra.jpg/450px-Egretta_sacra.jpg"],
    ["Spotted wood owl", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Strix_seloputo_-_Pasir_Ris.jpg/200px-Strix_seloputo_-_Pasir_Ris.jpg"],
    ["White-throated kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Halcyon_smyrnensis_-_Singapore_Botanic_Gardens.jpg/450px-Halcyon_smyrnensis_-_Singapore_Botanic_Gardens.jpg"],
    ["Double-banded plover", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Charadrius_bicinctus_2_-_Boat_Harbour.jpg/450px-Charadrius_bicinctus_2_-_Boat_Harbour.jpg"],
    ["Bar-tailed godwit", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Limosa_lapponica_2_-_Taren_Point.jpg/450px-Limosa_lapponica_2_-_Taren_Point.jpg"],
    ["Pied bush chat", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Pied_bushchat_%28Saxicola_caprata_bicolor%29_female.jpg/300px-Pied_bushchat_%28Saxicola_caprata_bicolor%29_female.jpg"],
    ["Paddyfield pipit", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Paddyfield_pipit_%28Anthus_rufulus_rufulus%29.jpg/300px-Paddyfield_pipit_%28Anthus_rufulus_rufulus%29.jpg"],
    ["Madagascan magpie-robin", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Madagascar_magpie-robin_%28Copsychus_albospecularis_pica%29_female.jpg/450px-Madagascar_magpie-robin_%28Copsychus_albospecularis_pica%29_female.jpg"],
    ["Zebra dove", "//upload.wikimedia.org/wikipedia/commons/thumb/2/29/Geopelia_striata_1_crop_-_Chinese_Garden.jpg/450px-Geopelia_striata_1_crop_-_Chinese_Garden.jpg"],
    ["Mangrove pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Pitta_megarhyncha_1_-_Singapore.jpg/200px-Pitta_megarhyncha_1_-_Singapore.jpg"],
    ["Pink-necked green pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Treron_vernans_male_-_Kent_Ridge_Park.jpg/450px-Treron_vernans_male_-_Kent_Ridge_Park.jpg"],
    ["Brown rock chat", "//upload.wikimedia.org/wikipedia/commons/thumb/7/78/Brown_rock_chat_%28Oenanthe_fusca%29.jpg/371px-Brown_rock_chat_%28Oenanthe_fusca%29.jpg"],
    ["Hardhead", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Aythya_australis_male_-_Hurstville_Golf_Course.jpg/450px-Aythya_australis_male_-_Hurstville_Golf_Course.jpg"],
    ["Rufous-headed ground roller", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Rufous-headed_ground-roller_%28Atelornis_crossleyi%29.jpg/258px-Rufous-headed_ground-roller_%28Atelornis_crossleyi%29.jpg"],
    ["Amber Mountain rock thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/8/89/Amber_mountain_rock_thrush_%28Monticola_sharpei_erythronotus%29_male_2.jpg/450px-Amber_mountain_rock_thrush_%28Monticola_sharpei_erythronotus%29_male_2.jpg"],
    ["Yellow-faced honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/5/54/Caligavis_chrysops_-_Lake_Parramatta_Reserve.jpg/450px-Caligavis_chrysops_-_Lake_Parramatta_Reserve.jpg"],
    ["Sooty oystercatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Haematopus_fuliginosus_-_Doughboy_Head.jpg/450px-Haematopus_fuliginosus_-_Doughboy_Head.jpg"],
    ["Noisy pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Pitta_versicolor_-_Kembla_Heights.jpg/375px-Pitta_versicolor_-_Kembla_Heights.jpg"],
    ["Scarlet myzomela", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Myzomela_sanguinolenta_1_-_Windsor_Downs_Nature_Reserve.jpg/450px-Myzomela_sanguinolenta_1_-_Windsor_Downs_Nature_Reserve.jpg"],
    ["Golden-headed cisticola", "//upload.wikimedia.org/wikipedia/commons/thumb/9/97/Cisticola_exilis_-_Cornwallis_Rd.jpg/450px-Cisticola_exilis_-_Cornwallis_Rd.jpg"],
    ["Australian raven", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Corvus_coronoides_-_Doughboy_Head.jpg/450px-Corvus_coronoides_-_Doughboy_Head.jpg"],
    ["Black-chested snake eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Black-chested_snake-eagle_%28Circaetus_pectoralis%29.jpg/366px-Black-chested_snake-eagle_%28Circaetus_pectoralis%29.jpg"],
    ["Village weaver", "//upload.wikimedia.org/wikipedia/commons/thumb/a/af/Village_weaver_%28Ploceus_cucullatus_cucullatus%29_female.jpg/266px-Village_weaver_%28Ploceus_cucullatus_cucullatus%29_female.jpg"],
    ["Pied kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Pied_kingfisher_%28Ceryle_rudis_leucomelanurus%29_female.jpg/300px-Pied_kingfisher_%28Ceryle_rudis_leucomelanurus%29_female.jpg"],
    ["Cuban black hawk", "//upload.wikimedia.org/wikipedia/commons/thumb/9/96/Cuban_black_hawk_%28Buteogallus_gundlachii%29.jpg/200px-Cuban_black_hawk_%28Buteogallus_gundlachii%29.jpg"],
    ["Indian roller", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Indian_roller_%28Coracias_benghalensis_benghalensis%29.jpg/300px-Indian_roller_%28Coracias_benghalensis_benghalensis%29.jpg"],
    ["Roadside hawk", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Roadside_hawk_%28Rupornis_magnirostris%29_immature_2.jpg/239px-Roadside_hawk_%28Rupornis_magnirostris%29_immature_2.jpg"],
    ["Yellow-billed shrike", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Yellow-billed_shrike_%28Corvinella_corvina_corvina%29.jpg/300px-Yellow-billed_shrike_%28Corvinella_corvina_corvina%29.jpg"],
    ["Black-headed lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Black-headed_lapwing_%28Vanellus_tectus_tectus%29.jpg/300px-Black-headed_lapwing_%28Vanellus_tectus_tectus%29.jpg"],
    ["Cuban green woodpecker", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Cuban_green_woodpecker_%28Xiphidiopicus_percussus_percussus%29_female.JPG/300px-Cuban_green_woodpecker_%28Xiphidiopicus_percussus_percussus%29_female.JPG"],
    ["Pied-winged swallow", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Pied-winged_swallow_%28Hirundo_leucosoma%29.jpg/450px-Pied-winged_swallow_%28Hirundo_leucosoma%29.jpg"],
    ["Rufous-tailed flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Rufous-tailed_flycatcher_%28Myiarchus_validus%29.JPG/360px-Rufous-tailed_flycatcher_%28Myiarchus_validus%29.JPG"],
    ["Bare-faced curassow", "//upload.wikimedia.org/wikipedia/commons/thumb/4/45/Bare-faced_curassow_%28Crax_fasciolata%29_female_head.JPG/362px-Bare-faced_curassow_%28Crax_fasciolata%29_female_head.JPG"],
    ["Savanna hawk", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Savanna_hawk_%28Buteogallus_meridionalis%29.JPG/253px-Savanna_hawk_%28Buteogallus_meridionalis%29.JPG"],
    ["Malachite kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/9/94/Malachite_kingfisher_%28Corythornis_cristatus_cristatus%29.jpg/300px-Malachite_kingfisher_%28Corythornis_cristatus_cristatus%29.jpg"],
    ["Red-legged seriema", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Red-legged_seriema_%28Cariama_cristata%29_head.JPG/309px-Red-legged_seriema_%28Cariama_cristata%29_head.JPG"],
    ["Saffron finch", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Saffron_finch_%28Sicalis_flaveola%29_male.JPG/402px-Saffron_finch_%28Sicalis_flaveola%29_male.JPG"],
    ["Giant kingfisher with tilapia", "//upload.wikimedia.org/wikipedia/commons/thumb/6/64/Giant_kingfisher_%28Megaceryle_maxima%29_female_composite.jpg/1198px-Giant_kingfisher_%28Megaceryle_maxima%29_female_composite.jpg"],
    ["Yellow-billed oxpeckers", "//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Yellow-billed_oxpeckers_%28Buphagus_africanus_africanus%29_on_zebra.jpg/450px-Yellow-billed_oxpeckers_%28Buphagus_africanus_africanus%29_on_zebra.jpg"],
    ["Goldcrest", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Goldcrest_1.jpg/400px-Goldcrest_1.jpg"],
    ["Bee-eaters", "//upload.wikimedia.org/wikipedia/commons/thumb/f/ff/African_bee-eaters_composite.jpg/199px-African_bee-eaters_composite.jpg"],
    ["Black-bellied whistling ducks", "//upload.wikimedia.org/wikipedia/commons/thumb/7/74/Black-bellied_whistling_ducks_%28Dendrocygna_autumnalis%29.jpg/739px-Black-bellied_whistling_ducks_%28Dendrocygna_autumnalis%29.jpg"],
    ["European robin", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d0/European_robin_%28Erithacus_rubecula%29_juvenile.jpg/450px-European_robin_%28Erithacus_rubecula%29_juvenile.jpg"],
    ["Eurasian blue tit", "//upload.wikimedia.org/wikipedia/commons/thumb/8/86/Eurasian_blue_tit_Lancashire.jpg/400px-Eurasian_blue_tit_Lancashire.jpg"],
    ["Brown pelican", "//upload.wikimedia.org/wikipedia/commons/thumb/7/75/Juvenile_pelecanus_occidentalis_in_flight.jpg/480px-Juvenile_pelecanus_occidentalis_in_flight.jpg"],
    ["Eurasian bullfinch", "//upload.wikimedia.org/wikipedia/commons/thumb/d/de/Bullfinch_male.jpg/420px-Bullfinch_male.jpg"],
    ["Chinstrap penguin", "//upload.wikimedia.org/wikipedia/commons/thumb/0/08/South_Shetland-2016-Deception_Island%E2%80%93Chinstrap_penguin_%28Pygoscelis_antarctica%29_04.jpg/200px-South_Shetland-2016-Deception_Island%E2%80%93Chinstrap_penguin_%28Pygoscelis_antarctica%29_04.jpg"],
    ["Nils Olav", "//upload.wikimedia.org/wikipedia/commons/thumb/1/14/Nils_Olav_inspects_the_Kings_Guard_of_Norway_after_being_bestowed_with_a_knighthood_at_Edinburgh_Zoo_in_Scotland.jpg/381px-Nils_Olav_inspects_the_Kings_Guard_of_Norway_after_being_bestowed_with_a_knighthood_at_Edinburgh_Zoo_in_Scotland.jpg"],
    ["Vervain hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Vervain_hummingbird_%28Mellisuga_minima%29.jpg/450px-Vervain_hummingbird_%28Mellisuga_minima%29.jpg"],
    ["Gentoo penguin", "//upload.wikimedia.org/wikipedia/commons/thumb/0/00/Brown_Bluff-2016-Tabarin_Peninsula%E2%80%93Gentoo_penguin_%28Pygoscelis_papua%29_03.jpg/450px-Brown_Bluff-2016-Tabarin_Peninsula%E2%80%93Gentoo_penguin_%28Pygoscelis_papua%29_03.jpg"],
    ["Japanese pygmy woodpecker", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Japanese_pygmy_woodpecker_in_Sakai%2C_Osaka%2C_February_2016.jpg/311px-Japanese_pygmy_woodpecker_in_Sakai%2C_Osaka%2C_February_2016.jpg"],
    ["Adélie penguins", "//upload.wikimedia.org/wikipedia/commons/thumb/4/40/Adelie_Penguins_on_iceberg.jpg/450px-Adelie_Penguins_on_iceberg.jpg"],
    ["Daurian redstart", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Daurian_redstart_at_Daisen_Park_in_Osaka%2C_January_2016.jpg/382px-Daurian_redstart_at_Daisen_Park_in_Osaka%2C_January_2016.jpg"],
    ["Tropical kingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Tropical_kingbird_%28Tyrannus_melancholicus%29.JPG/450px-Tropical_kingbird_%28Tyrannus_melancholicus%29.JPG"],
    ["Brown-eared bulbul", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a5/The_brown-eared_bulbul_after_playing_with_water.jpg/449px-The_brown-eared_bulbul_after_playing_with_water.jpg"],
    ["Black-necked stilt", "//upload.wikimedia.org/wikipedia/commons/thumb/3/37/Black-necked_Stilt_%28Himantopus_mexicanus%29%2C_Corte_Madera.jpg/241px-Black-necked_Stilt_%28Himantopus_mexicanus%29%2C_Corte_Madera.jpg"],
    ["Marsh wren", "//upload.wikimedia.org/wikipedia/commons/thumb/2/22/Cistothorus_palustris_CT.jpg/300px-Cistothorus_palustris_CT.jpg"],
    ["Indian vultures", "//upload.wikimedia.org/wikipedia/commons/thumb/3/30/Vultures_in_the_nest%2C_Orchha%2C_MP%2C_India_edit.jpg/411px-Vultures_in_the_nest%2C_Orchha%2C_MP%2C_India_edit.jpg"],
    ["Nacunda nighthawk", "//upload.wikimedia.org/wikipedia/commons/thumb/5/55/Nacunda_nighthawk.jpg/450px-Nacunda_nighthawk.jpg"],
    ["Red-banded fruiteater", "//upload.wikimedia.org/wikipedia/commons/thumb/4/40/PipreolaWhitelyiKeulemans.jpg/179px-PipreolaWhitelyiKeulemans.jpg"],
    ["Cliff flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Gib%C3%A3o_de_couro.jpg/225px-Gib%C3%A3o_de_couro.jpg"],
    ["Crested myna", "//upload.wikimedia.org/wikipedia/commons/thumb/0/07/Crested_myna%2C_Osaka%2C_Japan.jpg/375px-Crested_myna%2C_Osaka%2C_Japan.jpg"],
    ["Snowy egret", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Egretta_thula_at_Las_Gallinas_Wildlife_Ponds.jpg/231px-Egretta_thula_at_Las_Gallinas_Wildlife_Ponds.jpg"],
    ["Inland thornbill", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Inland_Thornbill_%285669197054%29_-_edit.jpg/471px-Inland_Thornbill_%285669197054%29_-_edit.jpg"],
    ["Black-tailed godwit", "//upload.wikimedia.org/wikipedia/commons/thumb/4/40/Black-tailed_Godwit_Uferschnepfe.jpg/300px-Black-tailed_Godwit_Uferschnepfe.jpg"],
    ["Rock ptarmigan", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Lagopus_muta_japonica_Mount_Tsubakuro.jpg/450px-Lagopus_muta_japonica_Mount_Tsubakuro.jpg"],
    ["Griffon vulture", "//upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Gypful.jpg/450px-Gypful.jpg"],
    ["Snowy owl", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Bubo_scandiacus_%28Linnaeus%2C_1758%29_Male.jpg/247px-Bubo_scandiacus_%28Linnaeus%2C_1758%29_Male.jpg"],
    ["Collared whitestart", "//upload.wikimedia.org/wikipedia/commons/thumb/d/da/Myioborus_torquatus_Santa_Elena.JPG/450px-Myioborus_torquatus_Santa_Elena.JPG"],
    ["Black lory", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Black_lory_%28Chalcopsitta_atra%29%2C_Gembira_Loka_Zoo%2C_Yogyakarta_2015-03-15_03.jpg/240px-Black_lory_%28Chalcopsitta_atra%29%2C_Gembira_Loka_Zoo%2C_Yogyakarta_2015-03-15_03.jpg"],
    ["Dusky lory", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Dusky_lory_%28Pseudeos_fuscata%29%2C_Gembira_Loka_Zoo%2C_Yogyakarta%2C_2015-03-15_03.jpg/219px-Dusky_lory_%28Pseudeos_fuscata%29%2C_Gembira_Loka_Zoo%2C_Yogyakarta%2C_2015-03-15_03.jpg"],
    ["Blue petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/5/53/Halobaena_caerulea_in_flight_-_SE_Tasmania.jpg/450px-Halobaena_caerulea_in_flight_-_SE_Tasmania.jpg"],
    ["Ring-billed gull", "//upload.wikimedia.org/wikipedia/commons/thumb/2/22/Portrait_of_ring-billed_gull_%28Larus_delawarensis%29%2C_Windsor%2C_Ontario%2C_2014-12-07.jpg/450px-Portrait_of_ring-billed_gull_%28Larus_delawarensis%29%2C_Windsor%2C_Ontario%2C_2014-12-07.jpg"],
    ["Eurasian eagle-owl", "//upload.wikimedia.org/wikipedia/commons/thumb/5/56/Bubo_bubo_sibiricus_-_01.JPG/200px-Bubo_bubo_sibiricus_-_01.JPG"],
    ["Feral pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Rock_Pigeon_Columba_livia.jpg/450px-Rock_Pigeon_Columba_livia.jpg"],
    ["Great tit", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Great_tit_side-on.jpg/430px-Great_tit_side-on.jpg"],
    ["European robin", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Erithacus_rubecula_with_cocked_head.jpg/375px-Erithacus_rubecula_with_cocked_head.jpg"],
    ["Long-billed curlew", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Long-billed_curlew_at_Drakes_Beach%2C_Point_Reyes.jpg/450px-Long-billed_curlew_at_Drakes_Beach%2C_Point_Reyes.jpg"],
    ["Yellow-billed shrike", "//upload.wikimedia.org/wikipedia/commons/thumb/3/31/Yellow_billed_shrike_with_prey.jpg/200px-Yellow_billed_shrike_with_prey.jpg"],
    ["King vulture", "//upload.wikimedia.org/wikipedia/commons/thumb/5/55/Sarcoramphus_papa_%28K%C3%B6nigsgeier_-_King_Vulture%29_-_Weltvogelpark_Walsrode_2013-01.jpg/450px-Sarcoramphus_papa_%28K%C3%B6nigsgeier_-_King_Vulture%29_-_Weltvogelpark_Walsrode_2013-01.jpg"],
    ["Lesser whistling duck", "//upload.wikimedia.org/wikipedia/commons/thumb/8/87/Dendrocygna_javanica_-_Chiang_Mai.jpg/200px-Dendrocygna_javanica_-_Chiang_Mai.jpg"],
    ["Flying Indian yellow-nosed albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/4/47/Thalassarche_carteri_in_flight_-_east_of_Port_Stephens.jpg/450px-Thalassarche_carteri_in_flight_-_east_of_Port_Stephens.jpg"],
    ["White-headed stilt", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Himantopus_leucocephalus_-_Hexham.jpg/450px-Himantopus_leucocephalus_-_Hexham.jpg"],
    ["Black-fronted dotterel", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Elseyornis_melanops_-_Chiltern.jpg/450px-Elseyornis_melanops_-_Chiltern.jpg"],
    ["Red-kneed dotterel", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Erythrogonys_cinctus_-_Chiltern.jpg/450px-Erythrogonys_cinctus_-_Chiltern.jpg"],
    ["Sharp-tailed sandpiper", "//upload.wikimedia.org/wikipedia/commons/thumb/9/94/Calidris_acuminata_-_Hexham_Swamp.jpg/450px-Calidris_acuminata_-_Hexham_Swamp.jpg"],
    ["Mandarin ducks", "//upload.wikimedia.org/wikipedia/commons/thumb/4/43/Pair_of_mandarin_ducks.jpg/400px-Pair_of_mandarin_ducks.jpg"],
    ["Common kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Common_Kingfisher_Alcedo_atthis.jpg/300px-Common_Kingfisher_Alcedo_atthis.jpg"],
    ["Willow tit", "//upload.wikimedia.org/wikipedia/commons/thumb/1/13/Poecile_montanus_kleinschmidti.jpg/400px-Poecile_montanus_kleinschmidti.jpg"],
    ["Ruddy kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Ruddy_Kingfisher.jpg/375px-Ruddy_Kingfisher.jpg"],
    ["Water rail", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Rallus_aquaticus_-_Ralaqu.jpg/450px-Rallus_aquaticus_-_Ralaqu.jpg"],
    ["Groundscraper thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Psophocichla_litsitsirupa_%28Etosha%29.jpg/200px-Psophocichla_litsitsirupa_%28Etosha%29.jpg"],
    ["Red-legged partridge", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Perdrix_rouge.jpg/450px-Perdrix_rouge.jpg"],
    ["Common redshank", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Common_Redshank_Tringa_totanus.jpg/450px-Common_Redshank_Tringa_totanus.jpg"],
    ["Pied kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Pied_kingfisher.jpg/375px-Pied_kingfisher.jpg"],
    ["Egyptian vulture", "//upload.wikimedia.org/wikipedia/commons/thumb/2/21/Neophron_percnopterus_-_01.jpg/450px-Neophron_percnopterus_-_01.jpg"],
    ["Ruby-throated hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Juvenile_Male_Ruby-throated_Hummingbird.jpg/450px-Juvenile_Male_Ruby-throated_Hummingbird.jpg"],
    ["Atlantic puffin", "//upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Papageitaucher_Fratercula_arctica.jpg/450px-Papageitaucher_Fratercula_arctica.jpg"],
    ["Eurasian sparrowhawk", "//upload.wikimedia.org/wikipedia/commons/thumb/1/17/Accipiter_nisus_edit.jpg/323px-Accipiter_nisus_edit.jpg"],
    ["Oriental pratincole", "//upload.wikimedia.org/wikipedia/commons/thumb/0/07/Glareola_maldivarum_-_Beung_Borapet.jpg/450px-Glareola_maldivarum_-_Beung_Borapet.jpg"],
    ["Blue-winged pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/4/41/Pitta_moluccensis_-_Kaeng_Krachan.jpg/450px-Pitta_moluccensis_-_Kaeng_Krachan.jpg"],
    ["Chat flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Bradornis_infuscatus.jpg/450px-Bradornis_infuscatus.jpg"],
    ["Hooded pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Pitta_sordida_-_Sri_Phang_Nga.jpg/450px-Pitta_sordida_-_Sri_Phang_Nga.jpg"],
    ["Bar-throated minla", "//upload.wikimedia.org/wikipedia/commons/thumb/6/61/Minla_strigula_-_Doi_Inthanon.jpg/450px-Minla_strigula_-_Doi_Inthanon.jpg"],
    ["Inca tern", "//upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Larosterna_inca_%28Inca_Tern_-_Inkaseeschwalbe%29_Weltvogelpark_Walsrode_2012-015.jpg/453px-Larosterna_inca_%28Inca_Tern_-_Inkaseeschwalbe%29_Weltvogelpark_Walsrode_2012-015.jpg"],
    ["Common blackbird", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Common_Blackbird.jpg/450px-Common_Blackbird.jpg"],
    ["Black-crowned night heron", "//upload.wikimedia.org/wikipedia/commons/thumb/3/39/Bihoreau_Gris.jpg/396px-Bihoreau_Gris.jpg"],
    ["Common raven", "//upload.wikimedia.org/wikipedia/commons/thumb/7/78/Corvus_corax_%28Common_Raven%29%2C_Yosemite_NP%2C_CA%2C_US_-_Diliff.jpg/424px-Corvus_corax_%28Common_Raven%29%2C_Yosemite_NP%2C_CA%2C_US_-_Diliff.jpg"],
    ["Red-bearded bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Nyctyornis_amictus_-_Kaeng_Krachan.jpg/200px-Nyctyornis_amictus_-_Kaeng_Krachan.jpg"],
    ["Long-tailed broadbill", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Psarisomus_dalhousiae_-_Kaeng_Krachan.jpg/200px-Psarisomus_dalhousiae_-_Kaeng_Krachan.jpg"],
    ["Noisy miner", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Manorina_melanocephala_domain.jpg/387px-Manorina_melanocephala_domain.jpg"],
    ["Malayan banded pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Hydrornis_irena_-_Sri_Phang_Nga.jpg/450px-Hydrornis_irena_-_Sri_Phang_Nga.jpg"],
    ["Mandarin duck", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Aix_galericulata_%28Male%29%2C_Richmond_Park%2C_UK_-_May_2013.jpg/445px-Aix_galericulata_%28Male%29%2C_Richmond_Park%2C_UK_-_May_2013.jpg"],
    ["Grey heron", "//upload.wikimedia.org/wikipedia/commons/thumb/3/39/Ardea_cinerea_-_Pak_Thale.jpg/300px-Ardea_cinerea_-_Pak_Thale.jpg"],
    ["Great cormorant", "//upload.wikimedia.org/wikipedia/commons/thumb/6/68/Phalacrocorax_carbo_Vic.jpg/200px-Phalacrocorax_carbo_Vic.jpg"],
    ["Pied avocet", "//upload.wikimedia.org/wikipedia/commons/thumb/0/08/Pied_Avocet_chick.jpg/450px-Pied_Avocet_chick.jpg"],
    ["Bar-tailed godwit", "//upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Bar-tailed_Godwit.jpg/450px-Bar-tailed_Godwit.jpg"],
    ["White-tailed eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Haliaeetus_albicilla_%28Svolv%C3%A6r%2C_2012%29.jpg/450px-Haliaeetus_albicilla_%28Svolv%C3%A6r%2C_2012%29.jpg"],
    ["Siberian rubythroat", "//upload.wikimedia.org/wikipedia/commons/thumb/3/36/Luscinia_calliope_-_Pak_Chong_2.jpg/450px-Luscinia_calliope_-_Pak_Chong_2.jpg"],
    ["Rusty-naped pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Pitta_oatesi_male_-_Mae_Wong.jpg/450px-Pitta_oatesi_male_-_Mae_Wong.jpg"],
    ["Marsh sandpiper", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Tringa_stagnatilis_2_-_Laem_Pak_Bia.jpg/450px-Tringa_stagnatilis_2_-_Laem_Pak_Bia.jpg"],
    ["Pacific golden plover", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Pluvialis_fulva_2_-_Laem_Pak_Bia.jpg/375px-Pluvialis_fulva_2_-_Laem_Pak_Bia.jpg"],
    ["Curlew sandpiper", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Calidris_ferruginea%2C_winter_adult%2C_Pak_Thale.jpg/450px-Calidris_ferruginea%2C_winter_adult%2C_Pak_Thale.jpg"],
    ["Black-naped monarch", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Hypothymis_azurea_-_Kaeng_Krachan.jpg/450px-Hypothymis_azurea_-_Kaeng_Krachan.jpg"],
    ["Siberian blue robin", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Luscinia_cyane_-_Khao_Yai.jpg/439px-Luscinia_cyane_-_Khao_Yai.jpg"],
    ["White-rumped shama", "//upload.wikimedia.org/wikipedia/commons/thumb/9/98/Copsychus_malabaricus_male_-_Khao_Yai.jpg/450px-Copsychus_malabaricus_male_-_Khao_Yai.jpg"],
    ["Richard's pipit", "//upload.wikimedia.org/wikipedia/commons/thumb/5/50/Anthus_richardi_-_Laem_Pak_Bia.jpg/450px-Anthus_richardi_-_Laem_Pak_Bia.jpg"],
    ["Common sandpiper", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Actitis_hypoleucos_-_Laem_Pak_Bia.jpg/450px-Actitis_hypoleucos_-_Laem_Pak_Bia.jpg"],
    ["Buff-banded rail", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Gallirallus_philippensis_Lord_Howe_Island_1.jpg/486px-Gallirallus_philippensis_Lord_Howe_Island_1.jpg"],
    ["White-necked laughingthrush", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Garrulax_strepitans_-_Mae_Wong.jpg/450px-Garrulax_strepitans_-_Mae_Wong.jpg"],
    ["Malaysian plover", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Charadrius_peronii_-_Laem_Pak_Bia.jpg/450px-Charadrius_peronii_-_Laem_Pak_Bia.jpg"],
    ["White-faced plover", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Charadrius_alexandrinus_-_Laem_Pak_Bia.jpg/450px-Charadrius_alexandrinus_-_Laem_Pak_Bia.jpg"],
    ["Lesser sand plover", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Charadrius_mongolus_-_Laem_Pak_Bia.jpg/450px-Charadrius_mongolus_-_Laem_Pak_Bia.jpg"],
    ["Painted stork", "//upload.wikimedia.org/wikipedia/commons/thumb/d/df/Mycteria_leucocephala_-_Pak_Thale.jpg/450px-Mycteria_leucocephala_-_Pak_Thale.jpg"],
    ["Spotted redshank", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Tringa_erythropus_-_Laem_Pak_Bia.jpg/450px-Tringa_erythropus_-_Laem_Pak_Bia.jpg"],
    ["Streaked spiderhunter", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Arachnothera_magna_-_Kaeng_Krachan.jpg/240px-Arachnothera_magna_-_Kaeng_Krachan.jpg"],
    ["Mute swan", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Mute_Swan_Emsworth2.JPG/396px-Mute_Swan_Emsworth2.JPG"],
    ["Blue pitta", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Pitta_cyanea_2_-_Khao_Yai.jpg/450px-Pitta_cyanea_2_-_Khao_Yai.jpg"],
    ["Pin-tailed snipe", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Gallinago_stenura_-_Laem_Pak_Bia.jpg/450px-Gallinago_stenura_-_Laem_Pak_Bia.jpg"],
    ["Whimbrel", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Whimbrel_Numenius_phaeopus.jpg/450px-Whimbrel_Numenius_phaeopus.jpg"],
    ["European bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Merops_apiaster_04.jpg/451px-Merops_apiaster_04.jpg"],
    ["European goldfinch", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Carcar.jpg/450px-Carcar.jpg"],
    ["Common starling", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Toulouse_-_Sturnus_vulgaris_-_2012-02-26_-_3.jpg/224px-Toulouse_-_Sturnus_vulgaris_-_2012-02-26_-_3.jpg"],
    ["Green heron", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Butorides_virescens2.jpg/258px-Butorides_virescens2.jpg"],
    ["Eastern phoebe", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Sayornis_phoebe_-Owen_Conservation_Park%2C_Madison%2C_Wisconsin%2C_USA-8.jpg/382px-Sayornis_phoebe_-Owen_Conservation_Park%2C_Madison%2C_Wisconsin%2C_USA-8.jpg"],
    ["Ruddy shelduck", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d0/A_couple_of_Tadorna_ferruginea.jpg/211px-A_couple_of_Tadorna_ferruginea.jpg"],
    ["European robin", "//upload.wikimedia.org/wikipedia/commons/thumb/9/95/Rouge_gorge_familier_-_crop_%28WB_correction%29.jpg/461px-Rouge_gorge_familier_-_crop_%28WB_correction%29.jpg"],
    ["Orange-bellied parrot", "//upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Neophema_chrysogaster_female_2_-_Melaleuca.jpg/360px-Neophema_chrysogaster_female_2_-_Melaleuca.jpg"],
    ["Myrtle warbler", "//upload.wikimedia.org/wikipedia/commons/thumb/6/61/Setophaga_coronata_MP.jpg/450px-Setophaga_coronata_MP.jpg"],
    ["Buffy hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/7/77/Leucippus_fallax.jpg/379px-Leucippus_fallax.jpg"],
    ["Great-winged petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/6/66/Pterodroma_macroptera_in_flight_3_-_SE_Tasmania.jpg/375px-Pterodroma_macroptera_in_flight_3_-_SE_Tasmania.jpg"],
    ["Sanderling", "//upload.wikimedia.org/wikipedia/commons/thumb/7/73/Calidris_alba_-_Laem_Phak_Bia.jpg/450px-Calidris_alba_-_Laem_Phak_Bia.jpg"],
    ["James's flamingos", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f6/James%27s_Flamingo_mating_ritual.jpg/450px-James%27s_Flamingo_mating_ritual.jpg"],
    ["Grey-headed albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/1/10/Thalassarche_chrysostoma_-_SE_Tasmania.jpg/450px-Thalassarche_chrysostoma_-_SE_Tasmania.jpg"],
    ["Blue-and-yellow macaw", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Ara_ararauna_Luc_Viatour.jpg/389px-Ara_ararauna_Luc_Viatour.jpg"],
    ["Great shearwater", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Puffinus_gravis_-_SE_Tasmania.jpg/471px-Puffinus_gravis_-_SE_Tasmania.jpg"],
    ["Wood duck", "//upload.wikimedia.org/wikipedia/commons/thumb/5/50/Wood_Duck_2%2C_St_James%27s_Park%2C_London_-_April_2012.jpg/450px-Wood_Duck_2%2C_St_James%27s_Park%2C_London_-_April_2012.jpg"],
    ["Bald eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Bald_Eagle_Portrait.jpg/240px-Bald_Eagle_Portrait.jpg"],
    ["Short-tailed shearwater", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Puffinus_tenuirostris_-_SE_Tasmania.jpg/450px-Puffinus_tenuirostris_-_SE_Tasmania.jpg"],
    ["Australian white ibis", "//upload.wikimedia.org/wikipedia/commons/thumb/6/60/Threskiornis_molucca_-_Perth.jpg/375px-Threskiornis_molucca_-_Perth.jpg"],
    ["Australian shelduck", "//upload.wikimedia.org/wikipedia/commons/thumb/9/94/Tadorna_tadornoides_female_1_-_Perth.jpg/450px-Tadorna_tadornoides_female_1_-_Perth.jpg"],
    ["Australian shelduck", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Tadorna_tadornoides_male_1_-_Perth.jpg/450px-Tadorna_tadornoides_male_1_-_Perth.jpg"],
    ["Red-necked avocet", "//upload.wikimedia.org/wikipedia/commons/thumb/8/85/Recurvirostra_novaehollandiae_in_flight_-_Lake_Joondalup.jpg/450px-Recurvirostra_novaehollandiae_in_flight_-_Lake_Joondalup.jpg"],
    ["Flying wandering albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Diomedea_exulans_in_flight_-_SE_Tasmania.jpg/492px-Diomedea_exulans_in_flight_-_SE_Tasmania.jpg"],
    ["Leaden flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Myiagra_rubecula_-_Australian_National_Botanic_Gardens_edit1.jpg/240px-Myiagra_rubecula_-_Australian_National_Botanic_Gardens_edit1.jpg"],
    ["Salvin's albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/8/86/Thalassarche_salvini_-_SE_Tasmania.jpg/450px-Thalassarche_salvini_-_SE_Tasmania.jpg"],
    ["White-necked petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/5/56/Pterodroma_cervicalis_-_SE_Tasmania.jpg/451px-Pterodroma_cervicalis_-_SE_Tasmania.jpg"],
    ["Australasian grebe", "//upload.wikimedia.org/wikipedia/commons/thumb/3/31/Tachybaptus_novaehollandiae_-_Mulligan%27s_Flat.jpg/450px-Tachybaptus_novaehollandiae_-_Mulligan%27s_Flat.jpg"],
    ["Northern carmine bee-eaters", "//upload.wikimedia.org/wikipedia/commons/thumb/8/86/Merops_nubicus_luc_viatour-crop.jpg/427px-Merops_nubicus_luc_viatour-crop.jpg"],
    ["Red-necked stint", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Calidris_ruficollis_-_Marion_Bay.jpg/400px-Calidris_ruficollis_-_Marion_Bay.jpg"],
    ["Swimming shy albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Thalassarche_cauta_-_SE_Tasmania.jpg/450px-Thalassarche_cauta_-_SE_Tasmania.jpg"],
    ["Buller's albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/9/90/Thalassarche_bulleri_in_flight_1_-_SE_Tasmania.jpg/450px-Thalassarche_bulleri_in_flight_1_-_SE_Tasmania.jpg"],
    ["Hooded dotterel", "//upload.wikimedia.org/wikipedia/commons/thumb/9/91/Thinornis_rubricollis_-_Orford.jpg/450px-Thinornis_rubricollis_-_Orford.jpg"],
    ["Fairy tern", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Sterna_nereis_-_Little_Swanport.jpg/450px-Sterna_nereis_-_Little_Swanport.jpg"],
    ["Great crested grebe", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Podiceps_cristatus_2_-_Lake_Dulverton.jpg/450px-Podiceps_cristatus_2_-_Lake_Dulverton.jpg"],
    ["Olive whistler", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Pachycephala_olivacea_-_Melaleuca.jpg/375px-Pachycephala_olivacea_-_Melaleuca.jpg"],
    ["Swimming wandering albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Diomedea_exulans_-_SE_Tasmania.jpg/450px-Diomedea_exulans_-_SE_Tasmania.jpg"],
    ["Brown thornbill", "//upload.wikimedia.org/wikipedia/commons/thumb/6/65/Acanthiza_pusilla_-_Austin%27s_Ferry.jpg/450px-Acanthiza_pusilla_-_Austin%27s_Ferry.jpg"],
    ["Tasmanian scrubwren", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Sericornis_humilis_-_Melaleuca.jpg/375px-Sericornis_humilis_-_Melaleuca.jpg"],
    ["Yellow-throated honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/9/95/Lichenostomus_flavicollis_stealing_hair_from_Thylogale_billardierii_for_nest_-_Melaleuca.jpg/375px-Lichenostomus_flavicollis_stealing_hair_from_Thylogale_billardierii_for_nest_-_Melaleuca.jpg"],
    ["Musk duck", "//upload.wikimedia.org/wikipedia/commons/thumb/8/80/Biziura_lobata_-_Sandford.jpg/450px-Biziura_lobata_-_Sandford.jpg"],
    ["Beautiful firetail", "//upload.wikimedia.org/wikipedia/commons/thumb/9/97/Stagonopleura_bella_female_-_Melaleuca.jpg/418px-Stagonopleura_bella_female_-_Melaleuca.jpg"],
    ["Orange-bellied parrot", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Neophema_chrysogaster_male_-_Melaleuca.jpg/375px-Neophema_chrysogaster_male_-_Melaleuca.jpg"],
    ["Black-browed albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/1/10/Thalassarche_melanophrys_-_SE_Tasmania.jpg/450px-Thalassarche_melanophrys_-_SE_Tasmania.jpg"],
    ["Cape petrel", "//upload.wikimedia.org/wikipedia/commons/thumb/5/53/Daption_capense_in_flight_-_SE_Tasmania.jpg/240px-Daption_capense_in_flight_-_SE_Tasmania.jpg"],
    ["Flying shy albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Thalassarche_cauta_in_flight_-_SE_Tasmania.jpg/450px-Thalassarche_cauta_in_flight_-_SE_Tasmania.jpg"],
    ["Spotted pardalote", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Pardalotus_punctatus_male_with_nesting_material_-_Risdon_Brook.jpg/375px-Pardalotus_punctatus_male_with_nesting_material_-_Risdon_Brook.jpg"],
    ["Australian brushturkey", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Alectura_lathami_-_Centenary_Lakes.jpg/375px-Alectura_lathami_-_Centenary_Lakes.jpg"],
    ["Australian golden whistler", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Pachycephala_pectoralis_-_Risdon_Brook.jpg/375px-Pachycephala_pectoralis_-_Risdon_Brook.jpg"],
    ["Striated pardalote", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Pardalotus_striatus_-_Risdon_Brook.jpg/375px-Pardalotus_striatus_-_Risdon_Brook.jpg"],
    ["Pied oystercatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Haematopus_longirostris_-_Austins_Ferry.jpg/450px-Haematopus_longirostris_-_Austins_Ferry.jpg"],
    ["Marabou stork", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Marabou_stork%2C_Leptoptilos_crumeniferus_edit1.jpg/302px-Marabou_stork%2C_Leptoptilos_crumeniferus_edit1.jpg"],
    ["Crowned lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Crowned_Lapwing_%28Vanellus_coronatus%29_Mikumi_shadow_lift.jpg/187px-Crowned_Lapwing_%28Vanellus_coronatus%29_Mikumi_shadow_lift.jpg"],
    ["Double-banded plover", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Charadrius_bicinctus_breeding_-_Ralphs_Bay.jpg/450px-Charadrius_bicinctus_breeding_-_Ralphs_Bay.jpg"],
    ["Grey-headed robin", "//upload.wikimedia.org/wikipedia/commons/thumb/3/37/Heteromyias_cinereifrons.jpg/408px-Heteromyias_cinereifrons.jpg"],
    ["Long-tailed fiscal", "//upload.wikimedia.org/wikipedia/commons/thumb/d/da/Juvenile_Long-tailed_Fiscals.jpg/234px-Juvenile_Long-tailed_Fiscals.jpg"],
    ["Black-headed heron", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Black-headed_Heron_%28Ardea_melanocephala%29.jpg/240px-Black-headed_Heron_%28Ardea_melanocephala%29.jpg"],
    ["Yellow-spotted honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Meliphaga_notata_-_Daintree_Village.jpg/450px-Meliphaga_notata_-_Daintree_Village.jpg"],
    ["Striated heron", "//upload.wikimedia.org/wikipedia/commons/thumb/5/56/Butorides_striatus_-_Daintree_River.jpg/450px-Butorides_striatus_-_Daintree_River.jpg"],
    ["Dark-sided thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/8/84/Zoothera_marginata_-_Doi_Inthanon.jpg/375px-Zoothera_marginata_-_Doi_Inthanon.jpg"],
    ["Papuan frogmouth", "//upload.wikimedia.org/wikipedia/commons/thumb/8/85/Podargus_papuensis_-_Daintree_River.jpg/200px-Podargus_papuensis_-_Daintree_River.jpg"],
    ["Rainbow bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/d/df/Merops_ornatus_-_Centenary_Lakes.jpg/240px-Merops_ornatus_-_Centenary_Lakes.jpg"],
    ["Mangrove robin", "//upload.wikimedia.org/wikipedia/commons/thumb/2/26/Peneoenanthe_pulverulenta_-_Cairns_Esplanade.jpg/240px-Peneoenanthe_pulverulenta_-_Cairns_Esplanade.jpg"],
    ["Azure kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/7/72/Alcedo_azurea_-_Julatten.jpg/450px-Alcedo_azurea_-_Julatten.jpg"],
    ["Pale-yellow robin", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Tregellasia_capito_-_Julatten.jpg/250px-Tregellasia_capito_-_Julatten.jpg"],
    ["Raja shelduck", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Tadorna_radjah_-_Centenary_Lakes.jpg/450px-Tadorna_radjah_-_Centenary_Lakes.jpg"],
    ["Dusky myzomela", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Myzomela_obscura_-_Daintree_Villiage.jpg/200px-Myzomela_obscura_-_Daintree_Villiage.jpg"],
    ["Straw-necked ibis", "//upload.wikimedia.org/wikipedia/commons/thumb/7/76/Threskiornis_spinicollis_-_Centenary_Lakes.jpg/375px-Threskiornis_spinicollis_-_Centenary_Lakes.jpg"],
    ["Bush stone-curlew", "//upload.wikimedia.org/wikipedia/commons/thumb/8/88/Burhinus_grallarius_-_Daintree_Villiage.jpg/300px-Burhinus_grallarius_-_Daintree_Villiage.jpg"],
    ["Long-toed stint", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Calidris_subminuta_-_Pak_Thale.jpg/406px-Calidris_subminuta_-_Pak_Thale.jpg"],
    ["Lesser sand plover", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Charadrius_mongolus_-_Laem_Phak_Bia.jpg/450px-Charadrius_mongolus_-_Laem_Phak_Bia.jpg"],
    ["Purple finch", "//upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Carpodacus_purpureus_CT4.jpg/450px-Carpodacus_purpureus_CT4.jpg"],
    ["Chinese pond heron", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Ardeola_bacchus_winter_plumage_-_Laem_Phak_Bia.jpg/375px-Ardeola_bacchus_winter_plumage_-_Laem_Phak_Bia.jpg"],
    ["White-rumped shama", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Copsychus_malabaricus_-_Khao_Yai.jpg/441px-Copsychus_malabaricus_-_Khao_Yai.jpg"],
    ["Black-breasted thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/7/79/Turdus_dissimilis_female_-_Ang_Khang.jpg/375px-Turdus_dissimilis_female_-_Ang_Khang.jpg"],
    ["Bar-backed partridge", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Arborophila_brunneopectus_male_-_Kaeng_Krachan.jpg/450px-Arborophila_brunneopectus_male_-_Kaeng_Krachan.jpg"],
    ["Wood sandpiper", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Tringa_glareola_-_Laem_Phak_Bia.jpg/450px-Tringa_glareola_-_Laem_Phak_Bia.jpg"],
    ["White-throated rock thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Monticola_gularis_male_non-breeding_-_Khao_Yai.jpg/200px-Monticola_gularis_male_non-breeding_-_Khao_Yai.jpg"],
    ["Plumbeous water redstart", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Rhyacornis_fuliginosus_-_Doi_Inthanon.jpg/240px-Rhyacornis_fuliginosus_-_Doi_Inthanon.jpg"],
    ["Black-winged stilt", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Himantopus_himantopus_-_Pak_Thale.jpg/450px-Himantopus_himantopus_-_Pak_Thale.jpg"],
    ["Chestnut-headed bee-eater", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Merops_leschenaulti_-_Kaeng_Krachan.jpg/450px-Merops_leschenaulti_-_Kaeng_Krachan.jpg"],
    ["Himalayan bluetail", "//upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tarsiger_rufilatus_-_Doi_Inthanon.jpg/375px-Tarsiger_rufilatus_-_Doi_Inthanon.jpg"],
    ["Red-wattled lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Vanellus_indicus_-_Laem_Phak_Bia.jpg/240px-Vanellus_indicus_-_Laem_Phak_Bia.jpg"],
    ["Tickell's blue flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/2/28/Cyornis_tickelliae_male_1_-_Kaeng_Krachan.jpg/450px-Cyornis_tickelliae_male_1_-_Kaeng_Krachan.jpg"],
    ["Rufous-tailed robin", "//upload.wikimedia.org/wikipedia/commons/thumb/7/72/Luscinia_sibilans_-_Khao_Yai.jpg/375px-Luscinia_sibilans_-_Khao_Yai.jpg"],
    ["Asian openbill", "//upload.wikimedia.org/wikipedia/commons/thumb/3/35/Anastomus_oscitans_-_Bueng_Boraphet.jpg/240px-Anastomus_oscitans_-_Bueng_Boraphet.jpg"],
    ["Freckled duck", "//upload.wikimedia.org/wikipedia/commons/thumb/3/37/Freckled-Duck-male.jpg/553px-Freckled-Duck-male.jpg"],
    ["Common greenshank", "//upload.wikimedia.org/wikipedia/commons/thumb/4/48/Tringa_nebularia_-_Laem_Phak_Bia.jpg/450px-Tringa_nebularia_-_Laem_Phak_Bia.jpg"],
    ["Black-backed swamphen", "//upload.wikimedia.org/wikipedia/commons/thumb/3/39/Porphyrio_indicus_viridis_-_Bueng_Boraphet%2C_Thailand.jpg/375px-Porphyrio_indicus_viridis_-_Bueng_Boraphet%2C_Thailand.jpg"],
    ["Blue whistling thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Myophonus_caeruleus_-_Ang_Khang_edit1.jpg/375px-Myophonus_caeruleus_-_Ang_Khang_edit1.jpg"],
    ["Black-capped kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Halcyon_pileata_-_Phra_Non.jpg/375px-Halcyon_pileata_-_Phra_Non.jpg"],
    ["Mountain bulbul", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Pycnonotus_flavescens_-_Kaeng_Krachan.jpg/375px-Pycnonotus_flavescens_-_Kaeng_Krachan.jpg"],
    ["Mugimaki flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Ficedula_mugimaki_-_Khao_Yai.jpg/240px-Ficedula_mugimaki_-_Khao_Yai.jpg"],
    ["Great knot", "//upload.wikimedia.org/wikipedia/commons/thumb/2/27/Calidris_tenuirostris_-_Laem_Phak_Bia.jpg/450px-Calidris_tenuirostris_-_Laem_Phak_Bia.jpg"],
    ["Swallow-tailed gull", "//upload.wikimedia.org/wikipedia/commons/thumb/8/84/Swallow-tailed-gull-dorsal.jpg/200px-Swallow-tailed-gull-dorsal.jpg"],
    ["Grey-tailed tattler", "//upload.wikimedia.org/wikipedia/commons/thumb/1/18/Tringa_brevipes.jpg/450px-Tringa_brevipes.jpg"],
    ["Nazca booby", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Nazca-Booby.jpg/450px-Nazca-Booby.jpg"],
    ["Blue-winged parrot", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Neophema_chrysostoma_mortimer_2.jpg/371px-Neophema_chrysostoma_mortimer_2.jpg"],
    ["Black-headed honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Melithreptus_affinis_Bruny.jpg/200px-Melithreptus_affinis_Bruny.jpg"],
    ["Hoary-headed grebe", "//upload.wikimedia.org/wikipedia/commons/thumb/6/69/Poliocephalus_poliocephalus_RB.jpg/450px-Poliocephalus_poliocephalus_RB.jpg"],
    ["Red-headed finch", "//upload.wikimedia.org/wikipedia/commons/thumb/4/42/Amadina_erythrocephala_%28l%29_edit.jpg/400px-Amadina_erythrocephala_%28l%29_edit.jpg"],
    ["Welcome swallow", "//upload.wikimedia.org/wikipedia/commons/thumb/3/31/Hirundo_neoxena_risdon.jpg/450px-Hirundo_neoxena_risdon.jpg"],
    ["Dusky robin", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Melanodryas_vittata_Bruny.jpg/252px-Melanodryas_vittata_Bruny.jpg"],
    ["Little penguin", "//upload.wikimedia.org/wikipedia/commons/thumb/8/81/Eudyptula_minor_family_exiting_burrow.jpg/442px-Eudyptula_minor_family_exiting_burrow.jpg"],
    ["Dusky woodswallow", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Artamus_cyanopterus_Mortimer.jpg/432px-Artamus_cyanopterus_Mortimer.jpg"],
    ["Fan-tailed cuckoo", "//upload.wikimedia.org/wikipedia/commons/thumb/5/52/Cacomantis_flabelliformis.jpg/200px-Cacomantis_flabelliformis.jpg"],
    ["Bassian thrush", "//upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Zoothera_lunulata_Bruny.jpg/387px-Zoothera_lunulata_Bruny.jpg"],
    ["Mallards", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Anas_platyrhynchos_male_female_quadrat.jpg/300px-Anas_platyrhynchos_male_female_quadrat.jpg"],
    ["Pink robin", "//upload.wikimedia.org/wikipedia/commons/thumb/3/30/Petroica_rodinogaster.jpg/375px-Petroica_rodinogaster.jpg"],
    ["Red-and-yellow barbet", "//upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Lake_Manyara_Bartvogel.jpg/200px-Lake_Manyara_Bartvogel.jpg"],
    ["Black-headed bunting", "//upload.wikimedia.org/wikipedia/commons/thumb/d/df/28-090504-black-headed-bunting-at-first-layby.jpg/450px-28-090504-black-headed-bunting-at-first-layby.jpg"],
    ["Crescent honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Phylidonyris_pyrrhopterus_male.jpg/385px-Phylidonyris_pyrrhopterus_male.jpg"],
    ["Eastern spinebill", "//upload.wikimedia.org/wikipedia/commons/thumb/0/01/Acanthorhynchus_tenuirostris_female.jpg/351px-Acanthorhynchus_tenuirostris_female.jpg"],
    ["Upland sandpiper", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cf/UplandSandpiperOntarioCropped.jpg/265px-UplandSandpiperOntarioCropped.jpg"],
    ["Eastern rosella", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Platycercus_eximius_diemenensis_female.jpg/450px-Platycercus_eximius_diemenensis_female.jpg"],
    ["Southern red bishop", "//upload.wikimedia.org/wikipedia/commons/thumb/9/94/Euplectes_sp_PLW_crop.jpg/205px-Euplectes_sp_PLW_crop.jpg"],
    ["Eastern great egret", "//upload.wikimedia.org/wikipedia/commons/thumb/0/06/Ardea_modesta.jpg/200px-Ardea_modesta.jpg"],
    ["Laughing kookaburra", "//upload.wikimedia.org/wikipedia/commons/thumb/1/19/Dacelo_novaeguineae_waterworks.jpg/410px-Dacelo_novaeguineae_waterworks.jpg"],
    ["Pine siskin", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Carduelis_pinus_CT7.jpg/450px-Carduelis_pinus_CT7.jpg"],
    ["Calliope hummingbirds", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Calliope-nest_edit.jpg/450px-Calliope-nest_edit.jpg"],
    ["Black kite", "//upload.wikimedia.org/wikipedia/commons/thumb/d/da/Milvus_migrans_-Kathmandu%2C_Nepal-444.jpg/450px-Milvus_migrans_-Kathmandu%2C_Nepal-444.jpg"],
    ["Coconut lorikeet", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Trichoglossus_haematodus_-Jurong_Bird_Park%2C_Singapore_-Dec2009.jpg/450px-Trichoglossus_haematodus_-Jurong_Bird_Park%2C_Singapore_-Dec2009.jpg"],
    ["Pine grosbeak", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Pine_grosbeak17g.jpg/399px-Pine_grosbeak17g.jpg"],
    ["Australian wood duck", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Australian_Wood_Duck_duckling.jpg/450px-Australian_Wood_Duck_duckling.jpg"],
    ["Crested pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Pigeon-Crested.jpg/427px-Pigeon-Crested.jpg"],
    ["Regent parrot", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Polytelis_anthopeplus_2_edit1.jpg/425px-Polytelis_anthopeplus_2_edit1.jpg"],
    ["Australian raven", "//upload.wikimedia.org/wikipedia/commons/thumb/b/be/Corvus_coronoides.jpg/206px-Corvus_coronoides.jpg"],
    ["Hildebrandt's starling", "//upload.wikimedia.org/wikipedia/commons/thumb/0/07/Lamprotornis_hildebrandti_-Tanzania-8-2c.jpg/235px-Lamprotornis_hildebrandti_-Tanzania-8-2c.jpg"],
    ["Crimson rosella", "//upload.wikimedia.org/wikipedia/commons/thumb/0/01/Platycercus_elegans_Wilsons_Prom.jpg/450px-Platycercus_elegans_Wilsons_Prom.jpg"],
    ["Golden parakeet", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Guaruba_guarouba_-Gramado_Zoo%2C_Brazil-8a.jpg/199px-Guaruba_guarouba_-Gramado_Zoo%2C_Brazil-8a.jpg"],
    ["Australian wood duck", "//upload.wikimedia.org/wikipedia/commons/thumb/8/89/Chenonetta_jubata_female_2.jpg/201px-Chenonetta_jubata_female_2.jpg"],
    ["Pied heron", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Ardea_picata.jpg/242px-Ardea_picata.jpg"],
    ["Star finch", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Neochmia_ruficauda.jpg/274px-Neochmia_ruficauda.jpg"],
    ["Tawny frogmouth", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Podargus_strigoides_Bonorong.jpg/200px-Podargus_strigoides_Bonorong.jpg"],
    ["Splendid fairywren", "//upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Splendid_Fairy_Wren_-_Lake_cargelligo_-_Spt_05_089.JPG/298px-Splendid_Fairy_Wren_-_Lake_cargelligo_-_Spt_05_089.JPG"],
    ["Common nighthawk", "//upload.wikimedia.org/wikipedia/commons/thumb/4/43/Chordeiles_minor_-British_Columbia_-Canada-8c.jpg/450px-Chordeiles_minor_-British_Columbia_-Canada-8c.jpg"],
    ["Cape Barren goose", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Cereopsis_novaehollandiae_2.jpg/384px-Cereopsis_novaehollandiae_2.jpg"],
    ["Little wattlebird", "//upload.wikimedia.org/wikipedia/commons/thumb/5/52/Anthochaera_chrysoptera_4.jpg/450px-Anthochaera_chrysoptera_4.jpg"],
    ["Roadside hawk", "//upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Buteo_magnirostris_-Goias_-Brazil-8.jpg/208px-Buteo_magnirostris_-Goias_-Brazil-8.jpg"],
    ["Masked lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Vanellus_miles_novaehollandiae.jpg/378px-Vanellus_miles_novaehollandiae.jpg"],
    ["Feeding little wattlebird", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Anthochaera_chrysoptera.jpg/450px-Anthochaera_chrysoptera.jpg"],
    ["Black swan", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Black_swan_jan09.jpg/450px-Black_swan_jan09.jpg"],
    ["Australasian darter", "//upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Darter_-_Anhinga_novaehollandiae%2C_Victoria%2C_Australia.jpg/200px-Darter_-_Anhinga_novaehollandiae%2C_Victoria%2C_Australia.jpg"],
    ["Sacred kingfisher", "//upload.wikimedia.org/wikipedia/commons/thumb/9/96/Sacred_kingfisher_nov08.jpg/200px-Sacred_kingfisher_nov08.jpg"],
    ["Whistling kite", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Whistling_kite_in_flight_edit_1.jpg/200px-Whistling_kite_in_flight_edit_1.jpg"],
    ["Jacky winter", "//upload.wikimedia.org/wikipedia/commons/thumb/d/db/Jacky_winter_nesting.jpg/450px-Jacky_winter_nesting.jpg"],
    ["Nesting willie wagtail", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Willie_wagtail_in_nest.jpg/450px-Willie_wagtail_in_nest.jpg"],
    ["Rufous whistler", "//upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Rufous_whistler.jpg/450px-Rufous_whistler.jpg"],
    ["Silver gull", "//upload.wikimedia.org/wikipedia/commons/thumb/4/46/Silver_gull_jan_09.jpg/450px-Silver_gull_jan_09.jpg"],
    ["Brown treecreeper", "//upload.wikimedia.org/wikipedia/commons/thumb/5/58/Brown_treecreeper_jan09.jpg/200px-Brown_treecreeper_jan09.jpg"],
    ["Magpie-lark", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Male_magpie_lark_in_suburban_garden.jpg/450px-Male_magpie_lark_in_suburban_garden.jpg"],
    ["Australian magpie", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Cracticus_tibicen_tibicen_juvenile_ANBG.jpg/200px-Cracticus_tibicen_tibicen_juvenile_ANBG.jpg"],
    ["Black currawong", "//upload.wikimedia.org/wikipedia/commons/thumb/6/65/Strepera_fuliginosa_4.jpg/200px-Strepera_fuliginosa_4.jpg"],
    ["Pacific black duck", "//upload.wikimedia.org/wikipedia/commons/thumb/4/47/Pacific_Black_Duck_jun08.jpg/450px-Pacific_Black_Duck_jun08.jpg"],
    ["Musk lorikeet", "//upload.wikimedia.org/wikipedia/commons/thumb/a/af/Musk_Lorikeet_jul08.jpg/200px-Musk_Lorikeet_jul08.jpg"],
    ["Chestnut teal", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Female_Chestnut_Teal_duck.jpg/450px-Female_Chestnut_Teal_duck.jpg"],
    ["Chestnut teal", "//upload.wikimedia.org/wikipedia/commons/thumb/4/45/Male_chestnut_teal.jpg/584px-Male_chestnut_teal.jpg"],
    ["Little pied cormorant", "//upload.wikimedia.org/wikipedia/commons/thumb/7/75/Microcarbo_melanoleucos_Austins_Ferry_3.jpg/450px-Microcarbo_melanoleucos_Austins_Ferry_3.jpg"],
    ["Tasmanian nativehen", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Gallinula_mortierii_1.jpg/297px-Gallinula_mortierii_1.jpg"],
    ["Superb lyrebird", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Superb_lyrbird_in_scrub.jpg/450px-Superb_lyrbird_in_scrub.jpg"],
    ["Eurasian skylark", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Skylark_2%2C_Lake_District%2C_England_-_June_2009.jpg/400px-Skylark_2%2C_Lake_District%2C_England_-_June_2009.jpg"],
    ["Royal spoonbill with open beak", "//upload.wikimedia.org/wikipedia/commons/thumb/2/20/Royal_Spoonbill_mouth_open.jpg/200px-Royal_Spoonbill_mouth_open.jpg"],
    ["Pied oystercatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Pied_Oystercatcher_on_beach.jpg/450px-Pied_Oystercatcher_on_beach.jpg"],
    ["Nankeen kestrel", "//upload.wikimedia.org/wikipedia/commons/thumb/d/da/Nankeen_kestrel_midflight.jpg/450px-Nankeen_kestrel_midflight.jpg"],
    ["Fulvous whistling duck", "//upload.wikimedia.org/wikipedia/commons/thumb/2/24/Dendrocygna_bicolor_wilhelma.jpg/429px-Dendrocygna_bicolor_wilhelma.jpg"],
    ["House sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/6/67/House_sparrow_portrait.jpg/450px-House_sparrow_portrait.jpg"],
    ["Spotted dove", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Streptopelia_chinensis_Tas_Edit.jpg/450px-Streptopelia_chinensis_Tas_Edit.jpg"],
    ["Southern crowned pigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/6/69/Goura_scheepmakeri_sclaterii_1_Luc_Viatour.jpg/217px-Goura_scheepmakeri_sclaterii_1_Luc_Viatour.jpg"],
    ["Scarlet robin", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Female_scarlet_robin.jpg/200px-Female_scarlet_robin.jpg"],
    ["Scarlet robin", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Petroica_boodang_Meehan_Range_1_crop.jpg/240px-Petroica_boodang_Meehan_Range_1_crop.jpg"],
    ["New Holland honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/8/81/Phylidonyris_novaehollandiae_Bruny_Island.jpg/386px-Phylidonyris_novaehollandiae_Bruny_Island.jpg"],
    ["Black phoebe", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Black_phoebe_sayornis_nigricans.jpg/200px-Black_phoebe_sayornis_nigricans.jpg"],
    ["Light-mantled albatross", "//upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Albatros_fuligineux.jpg/450px-Albatros_fuligineux.jpg"],
    ["Bananaquits", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Bananaquits.jpg/300px-Bananaquits.jpg"],
    ["Laughing kookaburra", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Laughing_kookaburra_dec08_02.jpg/200px-Laughing_kookaburra_dec08_02.jpg"],
    ["Pacific gull", "//upload.wikimedia.org/wikipedia/commons/thumb/7/73/Larus_pacificus_Bruny_Island.jpg/205px-Larus_pacificus_Bruny_Island.jpg"],
    ["Great white pelican", "//upload.wikimedia.org/wikipedia/commons/thumb/6/66/Whitepelican_edit_shadowlift.jpg/451px-Whitepelican_edit_shadowlift.jpg"],
    ["White-faced heron", "//upload.wikimedia.org/wikipedia/commons/thumb/8/83/White_faced_heron03.jpg/200px-White_faced_heron03.jpg"],
    ["Dusky moorhen", "//upload.wikimedia.org/wikipedia/commons/thumb/2/29/Dusky_moorhen442.jpg/400px-Dusky_moorhen442.jpg"],
    ["Superb fairywren", "//upload.wikimedia.org/wikipedia/commons/thumb/9/96/Female_superb_fairy_wren-edit1.jpg/302px-Female_superb_fairy_wren-edit1.jpg"],
    ["Rooster", "//upload.wikimedia.org/wikipedia/commons/thumb/6/60/Rooster_portrait2.jpg/200px-Rooster_portrait2.jpg"],
    ["Yellow-faced honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Yellow-faced_Honeyeater_nov07.jpg/200px-Yellow-faced_Honeyeater_nov07.jpg"],
    ["Domestic goose", "//upload.wikimedia.org/wikipedia/commons/thumb/3/39/Domestic_Goose.jpg/227px-Domestic_Goose.jpg"],
    ["Resting Australian pelicans", "//upload.wikimedia.org/wikipedia/commons/thumb/9/95/Australian_Pelicans.jpg/429px-Australian_Pelicans.jpg"],
    ["Eurasian coot", "//upload.wikimedia.org/wikipedia/commons/thumb/8/84/Eurasian_Coot.jpg/450px-Eurasian_Coot.jpg"],
    ["Piping plover", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Charadrius-melodus-004_edit.jpg/450px-Charadrius-melodus-004_edit.jpg"],
    ["Silvereye", "//upload.wikimedia.org/wikipedia/commons/thumb/2/22/Silvereye.jpg/300px-Silvereye.jpg"],
    ["Gadwall", "//upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Anas-strepera-001.jpg/450px-Anas-strepera-001.jpg"],
    ["American tree sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Spizella-arborea-002_edit2.jpg/300px-Spizella-arborea-002_edit2.jpg"],
    ["Tawny frogmouth", "//upload.wikimedia.org/wikipedia/commons/thumb/4/44/Tawny_frogmouth_wholebody444.jpg/400px-Tawny_frogmouth_wholebody444.jpg"],
    ["Crescent honeyeater", "//upload.wikimedia.org/wikipedia/commons/thumb/9/91/Crescent_Honeyeater_Edit2.jpg/316px-Crescent_Honeyeater_Edit2.jpg"],
    ["Australian wood duck", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Australian_wood_duck_-_male.jpg/200px-Australian_wood_duck_-_male.jpg"],
    ["Long-billed corella", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Long-billed_Corella.jpg/218px-Long-billed_Corella.jpg"],
    ["Ivory-billed woodpecker", "//upload.wikimedia.org/wikipedia/commons/thumb/3/39/Campephilus_principalisAWP066AA2.jpg/201px-Campephilus_principalisAWP066AA2.jpg"],
    ["Greater yellowlegs", "//upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Yellowlegs_-_natures_pics_edit.jpg/450px-Yellowlegs_-_natures_pics_edit.jpg"],
    ["Eastern imperial eagle", "//upload.wikimedia.org/wikipedia/commons/thumb/3/34/Kaiseradler_Aquila_heliaca_2_amk.jpg/201px-Kaiseradler_Aquila_heliaca_2_amk.jpg"],
    ["Green violetear", "//upload.wikimedia.org/wikipedia/commons/thumb/d/db/Colibri-thalassinus-001-edit.jpg/480px-Colibri-thalassinus-001-edit.jpg"],
    ["Bicolored antbird", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Gymnopithys-leucaspis-001_edit2.jpg/300px-Gymnopithys-leucaspis-001_edit2.jpg"],
    ["Yellowish flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Empidonax-flavescens-001.jpg/480px-Empidonax-flavescens-001.jpg"],
    ["Snowy plover", "//upload.wikimedia.org/wikipedia/commons/thumb/3/38/Snowy_Plover_srgb.jpg/450px-Snowy_Plover_srgb.jpg"],
    ["Bearded vulture", "//upload.wikimedia.org/wikipedia/commons/thumb/2/23/Bartgeier_Gypaetus_barbatus_front_Richard_Bartz.jpg/205px-Bartgeier_Gypaetus_barbatus_front_Richard_Bartz.jpg"],
    ["Greater yellowlegs", "//upload.wikimedia.org/wikipedia/commons/thumb/2/20/Greater_Yellowlegs2.jpg/240px-Greater_Yellowlegs2.jpg"],
    ["Brolga", "//upload.wikimedia.org/wikipedia/commons/thumb/0/06/Brolga-1-Healesville%2C-Vic%2C-3.1.2008_edit.jpg/200px-Brolga-1-Healesville%2C-Vic%2C-3.1.2008_edit.jpg"],
    ["Bufflehead", "//upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Bucephala-albeola-010.jpg/450px-Bucephala-albeola-010.jpg"],
    ["Welcome swallow", "//upload.wikimedia.org/wikipedia/commons/thumb/8/85/Swallow_chicks444.jpg/388px-Swallow_chicks444.jpg"],
    ["American robin", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Turdus-migratorius-002.jpg/396px-Turdus-migratorius-002.jpg"],
    ["Restless flycatcher", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Restless_flycatcher04.jpg/450px-Restless_flycatcher04.jpg"],
    ["Drinking Canada goose", "//upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Canada_goose_reflection_03.jpg/204px-Canada_goose_reflection_03.jpg"],
    ["Dunlin", "//upload.wikimedia.org/wikipedia/commons/thumb/6/62/Calidris-alpina-001_edit.jpg/450px-Calidris-alpina-001_edit.jpg"],
    ["Red-capped plover", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Red-capped_plover_chick444.jpg/468px-Red-capped_plover_chick444.jpg"],
    ["Black vulture", "//upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Coragyps-atratus-001.jpg/200px-Coragyps-atratus-001.jpg"],
    ["Chipping sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/2/29/Spizella-passerina-015_edit.jpg/450px-Spizella-passerina-015_edit.jpg"],
    ["Red-headed woodpecker", "//upload.wikimedia.org/wikipedia/commons/thumb/9/94/Melanerpes-erythrocephalus-003.jpg/300px-Melanerpes-erythrocephalus-003.jpg"],
    ["California condor", "//upload.wikimedia.org/wikipedia/commons/thumb/e/ec/California-Condor3-Szmurlo_edit.jpg/236px-California-Condor3-Szmurlo_edit.jpg"],
    ["Great blue heron", "//upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Le_Grand_Heron.jpg/448px-Le_Grand_Heron.jpg"],
    ["Magpie goose", "//upload.wikimedia.org/wikipedia/commons/thumb/0/03/Magpie_Goose_taking_off.jpg/480px-Magpie_Goose_taking_off.jpg"],
    ["Greater crested tern", "//upload.wikimedia.org/wikipedia/commons/thumb/1/12/Crested_tern444_edit.jpg/525px-Crested_tern444_edit.jpg"],
    ["African wattled lapwing", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Wattled_Plover_Mara_edit3.jpg/269px-Wattled_Plover_Mara_edit3.jpg"],
    ["Eastern screech owl", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Eastern_Screech_Owl.jpg/200px-Eastern_Screech_Owl.jpg"],
    ["White-crowned sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/9/92/White-crowned-Sparrow.jpg/450px-White-crowned-Sparrow.jpg"],
    ["American yellow warbler", "//upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Dendroica-aestiva-001.jpg/450px-Dendroica-aestiva-001.jpg"],
    ["Comparison of beaks", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b4/BirdBeaksA.svg/99px-BirdBeaksA.svg.png"],
    ["Wilson's snipe", "//upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Common_snipe_fencepost.jpg/292px-Common_snipe_fencepost.jpg"],
    ["Glossy ibis", "//upload.wikimedia.org/wikipedia/commons/thumb/5/54/Plegadis_falcinellus_%28aka%29_background_blurred.jpg/323px-Plegadis_falcinellus_%28aka%29_background_blurred.jpg"],
    ["Common blackbird", "//upload.wikimedia.org/wikipedia/commons/thumb/9/99/Amsel_Weibchen_aufgeplustert_edit2_clone.jpg/441px-Amsel_Weibchen_aufgeplustert_edit2_clone.jpg"],
    ["Tawny owl", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Tawny_wiki_edit1.jpg/202px-Tawny_wiki_edit1.jpg"],
    ["Magnificent hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Eugenes-fulgens-001.jpg/300px-Eugenes-fulgens-001.jpg"],
    ["Red-necked grebe", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Podiceps-grisegena-008.jpg/375px-Podiceps-grisegena-008.jpg"],
    ["Willet", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Catoptrophorus_semipalmatus_edit.jpg/367px-Catoptrophorus_semipalmatus_edit.jpg"],
    ["Black-bellied whistling duck", "//upload.wikimedia.org/wikipedia/commons/thumb/2/25/Whistling_duck_flight02_-_natures_pics-edit1.jpg/411px-Whistling_duck_flight02_-_natures_pics-edit1.jpg"],
    ["Rock dove", "//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Rock_dove_-_natures_pics.jpg/450px-Rock_dove_-_natures_pics.jpg"],
    ["Richard's pipit", "//upload.wikimedia.org/wikipedia/commons/thumb/d/db/Pippit-closer.jpg/400px-Pippit-closer.jpg"],
    ["Black-headed gull", "//upload.wikimedia.org/wikipedia/commons/thumb/1/13/Black-headed_Gull_-_St_James%27s_Park%2C_London_-_Nov_2006.jpg/405px-Black-headed_Gull_-_St_James%27s_Park%2C_London_-_Nov_2006.jpg"],
    ["Spruce grouse", "//upload.wikimedia.org/wikipedia/commons/thumb/5/54/Falcipennis-canadensis-001.jpg/450px-Falcipennis-canadensis-001.jpg"],
    ["House sparrow", "//upload.wikimedia.org/wikipedia/commons/thumb/3/32/House_sparrow04.jpg/450px-House_sparrow04.jpg"],
    ["American wigeon", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Anas-americana-004.jpg/450px-Anas-americana-004.jpg"],
    ["Black-chinned hummingbird", "//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Archilochus-alexandri-002-edit.jpg/450px-Archilochus-alexandri-002-edit.jpg"],
    ["Barred owl", "//upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Strix-varia-005.jpg/450px-Strix-varia-005.jpg"],
    ["Blue jay", "//upload.wikimedia.org/wikipedia/commons/thumb/0/04/Cyanocitta-cristata-004.jpg/450px-Cyanocitta-cristata-004.jpg"],
    ["Western gull", "//upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Gull_ca_usa.jpg/400px-Gull_ca_usa.jpg"],
    ["Northern gannets", "//upload.wikimedia.org/wikipedia/commons/thumb/7/76/Two_Gannets_edit_2.jpg/450px-Two_Gannets_edit_2.jpg"],
    ["Long-billed curlew", "//upload.wikimedia.org/wikipedia/commons/thumb/0/08/Curlew_-_natures_pics.jpg/450px-Curlew_-_natures_pics.jpg"],
    ["Canada goose", "//upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Canada_goose_flight_cropped_and_NR.jpg/576px-Canada_goose_flight_cropped_and_NR.jpg"],
    ["Red crossbill", "//upload.wikimedia.org/wikipedia/commons/thumb/4/49/Red_Crossbills_%28Male%29.jpg/450px-Red_Crossbills_%28Male%29.jpg"],
    ["Greylag goose", "//upload.wikimedia.org/wikipedia/commons/thumb/3/30/Greylag_Goose_in_St_James%27s_Park%2C_London_-_May_2006.jpg/543px-Greylag_Goose_in_St_James%27s_Park%2C_London_-_May_2006.jpg"],
    ["Prothonotary warbler", "//upload.wikimedia.org/wikipedia/commons/thumb/5/55/Protonotaria-citrea-002_edit.jpg/300px-Protonotaria-citrea-002_edit.jpg"],
    ["Hummingbirds", "//upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Haeckel_Trochilidae.jpg/214px-Haeckel_Trochilidae.jpg"],
    ["White-breasted nuthatch", "//upload.wikimedia.org/wikipedia/commons/thumb/0/09/Sitta-carolinensis-001.jpg/300px-Sitta-carolinensis-001.jpg"],
    ["Malurus cyaneus cyanochlamys", "//upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Superb_fairy_wren2_LiquidGhoul.jpg/400px-Superb_fairy_wren2_LiquidGhoul.jpg"],
    ["Red-footed booby", "//upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Sula_sula_nesting_in_Heliotropium_foertherianum.jpg/400px-Sula_sula_nesting_in_Heliotropium_foertherianum.jpg"],
    ["Snowy egret", "//upload.wikimedia.org/wikipedia/commons/thumb/8/81/Egretta_thula1.jpg/196px-Egretta_thula1.jpg"],
    ["Double-crested cormorant", "//upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Phalacrocorax-auritus-007.jpg/300px-Phalacrocorax-auritus-007.jpg"],
    ["Red-whiskered bulbul", "//upload.wikimedia.org/wikipedia/commons/thumb/5/57/Red-whiskered_Bulbul-web.jpg/289px-Red-whiskered_Bulbul-web.jpg"],
    ["Red-crested pochard", "//upload.wikimedia.org/wikipedia/commons/thumb/5/50/Netta_rufina_%28Red-crested_Pochard%29_Male%2C_London_Wetland_Centre_-_Diliff.jpg/450px-Netta_rufina_%28Red-crested_Pochard%29_Male%2C_London_Wetland_Centre_-_Diliff.jpg"],
]

const dateSince = (date) => {
  let first = new Date('2024-02-24')
  return Math.floor((date-first)/(1000*60*60*24));
}


function App() {
  const [ guesses, setGuesses ] = useState([]);
  const [ guess, setGuess ] = useState('');
  const [ bird, setBird ] = useState();
  const [ numGuesses, setNumGuesses ] = useState(0);
  const [ filterList, setFilterList ] = useState([]);
  const [ complete, setComplete ] = useState(0);

  const [ howToPopup, setHowToPopup ] = useState(false)
  const [ infoPopup, setInfoPopup ] = useState(false)
  const [ scorePopup, setScorePopup ] = useState(false)
  const [cookies, setCookie] = useCookies();
  const [ toast, setToast ] = useState('');
  const [ toastShown, setToastShown ] = useState(false);

  var today = new Date();

  useEffect(() => {
    console.log("dateSince(today)", dateSince(today))
    setBird(birds[dateSince(today)])

    if(cookies.day === formatDate(today)){
      setScorePopup(true)
      setComplete(cookies.prevOutcome)
      setNumGuesses((parseInt(cookies.prevNumGuesses) || 0))
      setGuesses((cookies.prevGuesses || []))
    }
  }, [])
  
  useEffect(() => {
    if(guess.length < 3){
      setFilterList([])
      return
    }
    for(var i in birds) {
        if(guess === birds[i][0]) {
            setFilterList([])
            return
        }
    }
    setFilterList(birds.filter(bird => bird[0].toLowerCase().includes(guess.toLowerCase())).slice(0, 5))
  }, [guess])

  useEffect(() => {
    setTimeout(function() {
      setToastShown(false)
    }, 3000);
  }, [toast])
  useEffect(() => {
    if(complete === 0) { return }
    if(cookies.day === formatDate(today)){ return }
    
    setCookie('prevGuesses', guesses, { path: '/', maxAge: 473040000 });
    if(complete !== 'loss') {
      setCookie(numGuesses, (parseInt(cookies[numGuesses]) || 0)+1, { path: '/', maxAge: 473040000 });
    } else {
      setCookie('loss', (parseInt(cookies['loss']) || 0)+1, { path: '/', maxAge: 473040000 });
    }
    setCookie("day", formatDate(today), { path: '/', maxAge: 473040000 });
    setCookie("prevOutcome", complete, { path: '/', maxAge: 473040000 });
    setCookie("prevNumGuesses", numGuesses, { path: '/', maxAge: 473040000 });
    setCookie("played", (parseInt(cookies["played"]) || 0)+1, { path: '/', maxAge: 473040000 });

    if(complete === 'win') {
      setCookie("streak", (parseInt(cookies["streak"]) || 0)+1, { path: '/', maxAge: 473040000 });
      if ((parseInt(cookies["streak"]) || 0) >= (parseInt(cookies["maxStreak"]) || 0)) {
        setCookie("maxStreak", (parseInt(cookies["maxStreak"]) || 0)+1, { path: '/', maxAge: 473040000 });
      }
    } else if (complete === 'loss') {
      setCookie("streak", 0, { path: '/', maxAge: 473040000 });
    }
    
    setScorePopup(true)
  }, [complete])

  const submitGuess = (e) => {
    e.preventDefault()

    if(guess === '') {return}


    setFilterList([])
    setGuesses(prev => [...prev, guess])
    setGuess('')
    setNumGuesses(prev => prev+1)

    if(guess.toLowerCase() === bird[0].toLowerCase()) {
      setComplete("win");

      let congrats = ["Nice one!", "Well done!", "Awesome!", "Good job!", "Incredible.", "Love to see it.", "Madness.", "Legendary."]

      setToastShown(true)
      setToast(congrats[Math.floor(Math.random() * congrats.length)])
      return
    }

    if(numGuesses === 4) {
      setComplete("loss");
      setToastShown(true)
      setToast("Oops, better luck next time.")
      return
    }
  }


  const getMaxScore = () => {
    let max = 0;
    for(let i = 0; i < 6; i++) {
      let score = (parseInt(cookies[i]) || 0)
      if (score > max ){
        max = score;
      }
    }
    return max;
  }

  const renderFilter = () => {
    if(filterList.length === 0) { return null }

    return (
      <div style={{width:'100%', display:'flex', flexDirection:'column', position:'absolute', bottom:'120%', background:'white', borderRadius:'5px', boxShadow:'1px 1px 4px 1px black'}}>
        {
          filterList.map((item) => {
            return (
              <span className="filter-option" onClick={() => {setGuess(item[0])}}>{item[0]}</span>
            )
          })
        }
      </div>
    )
  }

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          text: `🐦 Birdle ${formatDate(today)}\nAttempts: ${numGuesses}\n`,
          url:'https://birdle.uk'
        })
        .then(() => {
          setToastShown(true)
          setToast('Shared');
          return
        })
        .catch(() => {
          return
        });
    } else {
      navigator.clipboard.writeText(`🐦Birdle ${formatDate(today)}\nAttempts: ${numGuesses}\nhttps://birdle.uk`).then(() => {
        setToastShown(true)
        setToast('Copied to clipboard');
        return
      })    
    }
  }

  if(!bird) {
    return
  }

  return (
    <div style={{width:'100vw', maxWidth:'32rem', minHeight:'100vh', padding:'10px', background:'white'}}>
      <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column'}}>
        <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
          <div>
            <IconButton onClick={() => setScorePopup(true)}>
              <Leaderboard/>
            </IconButton>
          </div>
          <div>
            <IconButton onClick={() => setHowToPopup(true)}>
              <Help/>
            </IconButton>
            <IconButton onClick={() => setInfoPopup(true)}>
              <Info/>
            </IconButton>
          </div>
        </div>
        <h1 style={{textAlign:'center'}}>Birdle 🐦</h1>
        <img src={bird[1]} style={{height:'200px', maxWidth:'100%', margin:'auto', filter: `blur(${2*(5-numGuesses)}px)`}} className={`${complete}-image`} alt="Bird"/>
        <h1 className={`invisible ${complete}`}>{bird[0]}</h1>
        
        <div style={{width:'75%', margin:'auto'}}>
          <form onSubmit={submitGuess} style={{position:'relative'}}>
            <input disabled={numGuesses === 5 || complete} value={guess} onInput={e => setGuess(e.target.value.trim())} style={{fontSize:'1.2em', width:'100%', padding:'10px', borderRadius:'5px', border:'2px solid lightgrey'}} placeholder="Guess the bird"/>
            <input style={{cursor:'pointer', position:'absolute', right:0, height:'100%', borderRadius:'0 5px 5px 0', border:'2px solid #123d6e', background:'#3c7fc2', color:'white', fontWeight:'bold', fontSize:'1.2em'}} type='submit' value='>>'/>
            {renderFilter()}
          </form>
            {guesses.map((guess) => {
              return (
                <p style={{marginTop:'5px', marginBottom:'5px', width:'100%', padding:'5px', borderRadius:'5px', border:'2px solid lightgrey', background:'#eee'}}>{guess}</p>
              )
            })}
        </div>
      </div>
      <Popup key={"How-to-popup"} active={howToPopup} onToggle={(active) => setHowToPopup(active)}>
        <Card title={"How to play"} style={{width:'300px'}}>
            <p>Guess the bird in as few tries as possible.</p>
            <p>Birds refresh every day.</p>
            <p>You have five attempts, with the image coming into focus as you progress.</p>
        </Card>
      </Popup>
      <Popup key={"about-popup"} active={infoPopup} onToggle={(active) => setInfoPopup(active)}>
        <Card title={"About"} style={{width:'300px'}}>
            <p>Create by Thomas Barton, bird enthusiast.</p>
        </Card>
      </Popup>
      <Popup key={"score-popup"} active={scorePopup} onToggle={(active) => setScorePopup(active)}>
        <Card title={"Scores"}>
            <div style={{display:'flex', justifyContent:'space-between', width:'300px'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <CasinoOutlined/>
                Played
                <h2>{(parseInt(cookies["played"]) || 0)}</h2>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <LocalFireDepartment/>
                Streak
                <h2>{(parseInt(cookies["streak"]) || 0)}</h2>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <PercentOutlined/>
                Win %
                <h2>{(100 * ((parseInt(cookies["played"]) || 0) - (parseInt(cookies["loss"]) || 0)) / (parseInt(cookies["played"]) || 0)) || 0}</h2>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <SportsScoreOutlined/>
                Played
                <h2>{(parseInt(cookies["maxStreak"]) || 0)}</h2>
              </div>
            </div>
            <div>
              <div style={{width:'100%', display:'flex', alignContent:'center', fontSize:'1.3em', marginBottom:'5px'}}>
                <strong style={{marginRight:'10px'}}>1</strong>
                <div style={{display:'inline-block', minWidth:'max-content', padding:'2px', paddingLeft:'7px', paddingRight:'7px', textAlign:'right', color:'white', borderRadius:'5px', backgroundColor:'#3c7fc2', width:((parseInt(cookies["1"]) || 0) * 100 / getMaxScore())+'%'}}>
                {(parseInt(cookies["1"]) || 0)}
                </div>
              </div>
              <div style={{width:'100%', display:'flex', alignContent:'center', fontSize:'1.3em', marginBottom:'5px'}}>
                <strong style={{marginRight:'10px'}}>2</strong>
                <div style={{display:'inline-block', minWidth:'max-content', padding:'2px', paddingLeft:'7px', paddingRight:'7px', textAlign:'right', color:'white', borderRadius:'5px', backgroundColor:'#3c7fc2', width:((parseInt(cookies["2"]) || 0) * 100 / getMaxScore())+'%'}}>
                {(parseInt(cookies["2"]) || 0)}
                </div>
              </div>
              <div style={{width:'100%', display:'flex', alignContent:'center', fontSize:'1.3em', marginBottom:'5px'}}>
                <strong style={{marginRight:'10px'}}>3</strong>
                <div style={{display:'inline-block', minWidth:'max-content', padding:'2px', paddingLeft:'7px', paddingRight:'7px', textAlign:'right', color:'white', borderRadius:'5px', backgroundColor:'#3c7fc2', width:((parseInt(cookies["3"]) || 0) * 100 / getMaxScore())+'%'}}>
                {(parseInt(cookies["3"]) || 0)}
                </div>
              </div>
              <div style={{width:'100%', display:'flex', alignContent:'center', fontSize:'1.3em', marginBottom:'5px'}}>
                <strong style={{marginRight:'10px'}}>4</strong>
                <div style={{display:'inline-block', minWidth:'max-content', padding:'2px', paddingLeft:'7px', paddingRight:'7px', textAlign:'right', color:'white', borderRadius:'5px', backgroundColor:'#3c7fc2', width:((parseInt(cookies["4"]) || 0) * 100 / getMaxScore())+'%'}}>
                {(parseInt(cookies["4"]) || 0)}
                </div>
              </div>
              <div style={{width:'100%', display:'flex', alignContent:'center', fontSize:'1.3em'}}>
                <strong style={{marginRight:'10px'}}>5</strong>
                <div style={{display:'inline-block', minWidth:'max-content', padding:'2px', paddingLeft:'7px', paddingRight:'7px', textAlign:'right', color:'white', borderRadius:'5px', backgroundColor:'#3c7fc2', width:((parseInt(cookies["5"]) || 0) * 100 / getMaxScore())+'%'}}>
                {(parseInt(cookies["5"]) || 0)}
                </div>
              </div>
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <button onClick={() => share()} style={{alignItem:'center', fontSize:'1.1em', padding:'10px', marginTop:'20px', borderRadius:'5px', border:'2px solid #123d6e', background:'#3c7fc2', color:'white', fontWeight:'bold',}}>Share</button>
            </div>
        </Card>
      </Popup>
      <CookieConsent style={{backgroundColor:'white', color:'black', border:'2px solid lightgrey', borderRadius:'5px'}}  buttonStyle={{borderRadius:'5px', border:'2px solid #123d6e', background:'#3c7fc2', color:'white', fontWeight:'bold'}}>We use cookies to store anonymous user data.</CookieConsent>
      <div style={{padding:'10px', zIndex:10, transition:'500ms ease-in-out opacity', opacity: toastShown ? 1 : 0, bottom:'50px', left:'50%', transform:'translateX(-50%)', display:'flex', flexDirection:'column', position:'absolute', background:'white', borderRadius:'5px', boxShadow:'1px 1px 4px 1px black'}}>
            {toast}
      </div>    
    </div>

  );
}

export default App;


function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
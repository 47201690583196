import React, { useEffect, useState } from 'react'
import './Popup.css'


export default function Popup({active, children, onToggle})  {
    const [popupActive, setPopupActive] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPopupActive(active)
        setLoading(false)
    }, [active]);
    
    if(loading){return null}
    
    return (
        <div className={`shadow ${popupActive ? 'shadow-active' : ''}`} onClick={() => {setPopupActive(false); onToggle(false)}}>
            <div className={`popup ${popupActive ? 'popup-active' : ''}`} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )


}
import React from 'react'
import './Card.css'
import {Button, Card as MuiCard, Typography} from '@mui/material'
import { Box } from '@mui/system';

export default function Card({title, button, onButtonClick, onClick, children, formRef, className, style, id}) {


    const renderHeader = () => {
        if(!button && !title){return}

        return  <Box display={'flex'} justifyContent={'space-between'}>
                    {title && <Typography variant='h6' zIndex={3} width={'max-content'}>{title}</Typography>}
                    {button && <Button onClick={onButtonClick}>{button}</Button>}
                </Box>
    }


    return(
        <MuiCard id={id} className={"card " + className} style={style} onClick={onClick}>
            {renderHeader()}
            <div className='card-scroll'>
                {children}
            </div>
        </MuiCard>
    )
    
}